@import url(https://cdn.ckeditor.com/ckeditor5/42.0.0/ckeditor5.css);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-modal.backendropss {
    padding: 0;
}

.slide-modal .modalConfirms {
    float: right;
    width: 80%;
    height: 100%;
    right: 0;
    border-radius: 0;
    animation: slide 0.6s linear alternate;
    overflow-y: auto; 
}

.gridModal {
    height: 45em;
}

@keyframes slide {
    0% { width: 0; }
    10% { width: 10%; }
    20% { width: 20%; }
    30% { width: 30%; }
    40% { width: 40%; }
    50% { width: 50%; }
    55% { width: 55%; }
    60% { width: 60%; }
    65% { width: 65%; }
    70% { width: 70%; }
}

.loaderImage {
    margin-top: 20px;
    animation: fade 0.2s linear;
}

@keyframes fade {
    0% { margin-top: 40px; }
    50% { margin-top: 30px; }
    100% { margin-top: 20px; }
}

@keyframes gridSlide {
    0% { margin-top: 20px; }
    50% { margin-top: 10px; }
    100% { margin-top: 0px; }
}

.gridLoadRow {
    animation: gridSlide 0.2s linear;
}

.gridLoadRow .rowCheckbox {
    float: left;
    width: 16px;
    height: 16px;
    margin: 2px 30px 0px 40px;
}

.gridLoadRow .gridRow {
    float: left;
    width: 90%;
    height: 20px;
    border: none;
    border-radius: 0;
    opacity: 0.4;
}

@media only screen and (max-width: 1100px) {
    .gridLoadRow .gridRow {
        width: 80%;
    }
}

@media only screen and (max-width: 700px) {
    .gridLoadRow .gridRow {
        width: 70%;
    }
}


.even {
    background-color: #d2dbe1;
}

.odd {
    background-color: #F2F2F2;
}

.even, .odd {
    border-radius: 5px;
}

.no-radius {
    border-radius: 0;
    border: 0 !important;
    background-color: #F2F2F2;
    height: 15px;
    margin-bottom: 15px;
}

@keyframes formSlide {
    0% { margin-top: 50px; }
    25% { margin-top: 45px; }
    50% { margin-top: 40px; }
    50% { margin-top: 35px; }
    100% { margin-top: 30px; }
}
.formLoadBlock {
    float: left;
    width: 100%;
    height: 50px;
    margin-top: 30px;
    animation: formSlide 0.2s linear;
}

.formLoadBlock .fieldHolder {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    border-radius: 5px;
}

.modal.fade.bd-example-modal-lg.in{
	overflow: auto;
    background-color: rgb(0 0 0 / 57%);
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000
}
.ck-editor__editable_inline {
    min-height: 400px;
    padding-left: 30px !important;
}
.field img {
    width: 40px;
    border-radius: 100%;
    height: 40px;
}

.input-board {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #f0eae7;
    border: 4px dashed #95d472;
    height: 30%!important;
}

.input-board.beige {
    background-color: beige;
}

.input-board .upload-button {
    margin: 10px 0;
}

.input-board .upload-button i {
    margin: 0;
}
.default-image{
    width: 40px !important;
    border-radius: 100%;
    height: 40px !important;
}
.company-logo-image{
    max-width: 150px;
}
body{
  background: inherit;
}
.tableCollection table {
    width: 100%;
}

.ant-picker.ant-picker-disabled {
  min-width: 120px;
  background: #ededed!important;
}

.popUpTable .css-1rtrksz {
    max-width: 110px;
}

.tableCollection {
    display: block;
    position: relative;
}

.tableCollection table > thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.tableCollection table > thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}

.tableCollection table tbody td{
    border: 1px solid #ccc;
}
.tableCollection table tbody td .highlightField{
  background-color: #ff000047;
}
.tableCollection table tbody tr.isDuplicate td.rowNumber{
    background-color: #ff8d00 !important;
    padding: 10px !important;
    text-align: center;
}

.tableCollection .foundRow{
    border: 2px solid red;
}

.tableCollection table tbody td.rowNumber{
    background-color: #eaf0f5;
    padding: 10px !important;
    text-align: center;
}
.searchLine-quantity{
    min-width: 160px!important;
    line-height: 2.5;
}
.searchLine-quantity input[type='number']{
    min-width: 10px !important;
}

.form-treeview-quantity input[type='number']{
    min-width: 20px!important;
    width: 70px;
}

.tableCollection table td.left, .tableCollection table td.right {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    width: 10px;
    text-align: center;
}

.tableCollection table td.left span i, .tableCollection table td.right span i {
        color: #8c9cb0;
        font-size: 16px;
}
.tableCollection table td.right span i:hover {
     color: red;
}
.tableCollection table td.right {
    border-left: 1px solid #ccc !important;
    padding-left: 10px;
}

.tableCollection table td.left {
    border-right: 1px solid #ccc !important;
    padding-right: 10px;
}

.tableCollection table td.tableHeader {
    background-color: #f0eae7 !important;
}

.tableCollection table td.tableHeader.popup {
    background-color: #ffffff !important;
}

.tableCollection table td.tableHeader.white {
    background-color: white !important;
}

.tableCollection table td i {
    cursor: pointer;
}

.tableCollection input[type='text'], .tableCollection input[type='number'] {
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
    color: black;
}
.tableCollection input[type='number']  {
    padding: 0 !important;
}

.tableCollection .css-1aya2g8, .tableCollection .css-2o5izw {
    border-radius: 0!important;
    color: black;
}
.tableCollection .css-rsyb7x {
    min-width: 100px;
    color: black;
}
.tableCollection .css-10nd86i, .tableCollection .css-1sontr1 {
    min-width: 189px;
    color: black;
}

.tableCollection .css-1sontr1 .css-1492t68{
    display: none;
}
.input-group-addon {
    background-color: transparent!important;
}
.panel-heading input[type="text"]:focus  {
    border-right: 1px solid #44c699!important;
}
.percent-input {
    border-right: none!important;
}

.updatedNumber .ant-input-number-handler-wrap{
    display: none;
}

.custom-table{
    overflow-x: auto;
    overflow-y: hidden;
}
table.mobileTable{
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 1px #aaa;
    margin: 0;
  }
.mobileTable tbody:nth-child(2n+1){
    background: #eaf0f5;
}
.modal-header{
      z-index: 1050!important;
}
.mobileTable tbody{
    background: #f8f9fc;
}
.mobileTable tbody > tr{
    padding: 5px;
}
table.mobileTable .mobileTable-td{
    border: none;
    position: relative;
    white-space: normal;
    text-align: left;
}
.mobileTable-label{
  padding-right: 10px;
   white-space: nowrap;
   text-align: left;
   font-weight: bold;
   width: 100px;
}
.searchBar{
    display: flex;
    align-items: baseline;
}
.searchBar input [type="text"]{
  width: 400px;
  border-radius: 3px;
}
.rectangular_button{
  text-decoration: none;
  padding: 3px 23px 3px 23px;
  border-radius: 3px;
  background: white;
}
.rectangular_button:hover {
  border-bottom-width: 2px;
}
.form-input-field .ant-select-selection--single{
  border: 1px solid #d6d6d6!important;
  border-radius: 3px!important;
}
.form-input-field .ant-select-selection__rendered{
  line-height: 40px!important;
}
.orderItem-select .ant-select-arrow i {
    font-size: 14px;
    line-height: normal;
}
.orderItem-select span.ant-select-arrow{
    margin: -5px;
  }
/* TreeView */


.searchBar .button-primary{
  margin-top: 0px;
}
.table .ant-tree li .ant-tree-node-content-wrapper {
    white-space: normal;
    height: auto;
}
.table .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected, .table .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
    white-space: inherit;
}

.updatedNumber.ant-input-number .ant-input-number-input-wrap input{
  text-align: right;
  padding-right: 10px!important;
}

.ant-input-number .ant-input-number-input-wrap input{
  text-align: right;
  padding-right: 35px!important;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}

.tableCollection table td.borderNone.left{
    border-right: 1px solid #f0eae7 !important;
}
.tableCollection.tableOverflow{
  overflow-x: auto;
}

.tableCollection table td.borderNone.left.popup{
  border-right: 0px!important;
}

/* POS */

.pos-billing-data {
  background-color: white;
  margin-top: 0px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: 0 1px 1px #aaa;
  margin-bottom: 10px;
  overflow: auto;
}
.pos-customer .panel.total{
    min-height: 100px!important;
    padding-bottom: 10px;
    margin: 0px;
    margin-bottom: 15px;
}
.pos-customer .panel-heading{
    padding: 8px 25px;
}
.pos-customer .col-md-12 {
    padding-right: 10px;
}
.pos-customer .panel.total .form-group label{
  display: none;
}
.pos-billing-data .posTable{
    margin-bottom: 10px;
    width: 100%;
}
.pos-billing-data .posTable thead, .pos-billing-data .posTable tbody tr{
    border-bottom: 1px solid #cccccc;
}
.pos-billing-data .totals tr.line{
   border-top: 1px solid #cccccc;
}
.pos-billing-data .posTable td, .pos-billing-data .totals td{
    width: 100%;
    padding: 3px 5px;
}
.pos-billing-data .totals td{
    width: 80%;
}
 .pos-billing-data .totals td{
   padding-left: 10px;
   padding-right: 10px;
 }
.pos-billing-data .posTable tbody input{
    width: 100px;
    border: 1px solid #d6d6d6;
    font-weight: 600;
    text-decoration: underline;
    font-size: 15px;
    padding: 0px;
    min-width: 100px!important;
    margin-bottom: 0px;
}
.pos-billing-data .posTable thead td{
    min-width: 100px!important;
    text-align: center;
}

.pos-billing-data .posTable input[type='text']{
    width: 100%;
    min-width: 100px!important;
}
.pos-billing-data .posTable input[disabled]{
    background-color: inherit!important;
    text-decoration: none;
    border: none;
}

.pos-billing-data .totals{
    width: 100%;
}
.pos-billing-data .totals .value{
   padding-right: 50px;
}
.pos-billing-data .totals .value h4{
    float: right;
}
.pos-button .button-primary {
    width: 250px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
}
.pos-billing-data .posTable input[type='text'] {
  min-width: 100px!important;
}
.posCategory{
    margin-bottom: 5px;
}
.posCategory .ant-card:hover{
  border-top: 1px solid #44c699;
}
.posCategory-card{
   text-align: center;
   height: 160px;
   margin-right: 5px!important;
   align-items: center;
   display: grid;
}
.posCategory-sku{
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 15px;
}
.posCategory-price{
  font-size: 15px;
}
.posCategory-img{
    margin: 0 auto;
    max-width: 60px;
    margin-top: 10px;
}
.posCategory .ant-card-body{
    padding: 5px;
}
.pos.breadcrumbs{
    align-items: center;
    background-color: #d9e0ef;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 60px;
    height: 40px;
    display: grid;
    color: rgba(0, 0, 0, 0.65);
    padding: 0 10px;
  }
.posPage .search #search{
  padding-left: 30px!important;
}
.pos.breadcrumbs .ant-breadcrumb-link{
    cursor: pointer;
}
.pos.breadcrumbs .ant-breadcrumb-separator{
    margin: 0 5px;
  }
.left-mobileMenu{
   position: absolute;
   top: 6px;
   left: 0px;
   z-index: 1000;
}
.left-mobileMenu i{
    color: white;
    font-size: 30px;
    margin-left: 5px;
}
.left-menuPos{
   background-color: #f0eae7;
   position: absolute;
   top: 24px;
   left: 16px;
   z-index: 1000;
}
.list-tableLink {
    cursor: pointer;
    text-decoration: none;
}
.list-tableRow .top {
    font-weight: 600;
}
.list-tableRow .first {
    padding-right: 8px;
    border-right: 1px solid grey;
}
.list-tableRow .second {
    padding-left: 8px;
}
.list-tableRow .list-date, .list-tableRow .list-stage {
    color: #879697;
}
#pos-content .dashboard .moduleSubMenu h3, #pos-content .moduleSubMenu h3 {
    padding-left: 80px;
}
#makePayment .padding10{
  padding: 0px 10px!important;
}
.tableCollection .ant-select-disable .ant-select-selector{
    background: transparent;
}
.tableCollection .ant-select-disabled .ant-select-selection-item{
    color: #000;
}
label{
    font-size: 16px;
    color: inherit;
}
.table-inbox{
    font-size: 15px;
}
.heightAuto .css-1tkhqcq{
  height: auto;
}
.col-centered{
    float: none;
    margin: 0 auto;
}
.printDatasource{
    background-color: white;
    margin: auto;
    height: 1123px;
    max-width: 864px;
    margin-bottom: 50px;
    border-radius: 2px;
    box-shadow: 0 0 7px 0 #d4d4d4;
    overflow: auto;
    padding: 40px;
}

.dashboard-spinner{
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  padding: 15px;
  height: 100%;
  background-color: #fbfafa;
  opacity: 1;
  z-index: 10;
}

.ant-drawer-wrapper-body {
  height: 90%!important;
}
.ant-drawer-content {
  background-color: #f0eae7!important;
}


    /* Mobile*/

@media only screen and (max-width: 760px){
  .pos-categories{
    display: none;
  }
  .pos-customer .row.m-bot15 {
      margin: 0px;
  }
  .posPage .col-xs-12{
    padding-right: 15px;
  }
  .panel.total p.total {
    font-size: 17px;
  }
}

@media only screen and (max-width: 780px) {
    .custom-table{
        overflow-x: visible;
        overflow-y: visible;
    }
}

@media only screen and (max-width: 795px){
  .posCategor-card{
    margin-right: 0px;
  }
  .posCategory-img{
    max-width: 40px;
  }
  .pos-billing-data .posTable td{
    padding: 0px;
  }
  .pos-billing-data .posTable thead, .tableCollection table thead, .searchItem-table thead{
      display: none;
  }
  .pos-billing-data .posTable tbody td:before, .tableCollection table tbody td:before, .searchItem-table tbody td:before{
    content: attr(data-title);
  }
  .pos-billing-data .posTable tbody td:before, .tableCollection table tbody td:before, .searchItem-table tbody td:before{
      position: absolute;
      top: 6px;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
  }
    .tableCollection, .searchItem-table {
      width: 100%!important;
  }
  .tableCollection table tbody td:before, .searchItem-table tbody td:before{
      font-weight: bold;
      font-size: 14px;
  }
  .searchBar{
    display: grid;
    margin-bottom: 10px;
  }
  .searchBar .button-primary{
    margin-top: 10px;
  }
  .tableCollection input[type='number'], .searchItem-table input[type='number']{
    width: 70%;
    padding-right: 10px!important;
  }
  .searchBar input [type="text"]{
    width: 100%;
  }
  .searchBar input {
    width: auto;
  }
  .pos-billing-data .posTable tbody tr, .tableCollection table tbody tr, .searchItem-table tbody tr{
      display: block;
      background: white;
  }
  .pos-billing-data .posTable tbody tr:nth-child(2n+1) {
      background: #f8f9fc;
  }
  .tableCollection table tbody tr:nth-child(2n+1), .searchItem-table tbody tr:nth-child(2n+1){
      background: #eaf0f5;
  }
  .pos-billing-data .posTable tbody td, .tableCollection table td, .searchItem-table td{
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%!important;
    white-space: normal;
    text-align: left;
    min-height: 40px;
    display: block;
  }
  .pos-billing-data .totals td {
    width: auto;
  }
  .tableCollection table td, .searchItem-table tbody td{
    border-bottom: none!important;
  }
  .searchItem-table tbody td {
    border: 1px solid #ccc;
    border-bottom: none;
    }
  .tableCollection table tr, .searchItem-table tbody tr{
   border-bottom: 1px solid #ccc;
  }
  .pos-billing-data .totals td{
    padding: 0px 10px;
  }
  .printDatasource{
    padding: 0px;
  }
}

@media only screen and (max-width: 1200px){
  .pos-billing-data .posTable input[type='text']{
    min-width: 100px!important;
  }
  .pos-billing-data .posTable thead td, .pos-billing-data .posTable tbody input{
    min-width: 50px!important;
  }
  .pos-billing-data .posTable input{
      height: 30px;
      width: 100%!important;
      font-size: 13px!important;
      padding: 7px!important;
      text-align: left;
  }
  .posPage .panel-heading span {
    font-size: 13px;    margin-bottom: 10px;
  }
 .slide-modal .modalConfirms{
    width: 95%;
  }
  .add-customer-label>i {
    font-size: 16px;
    margin: 0 3px 0 0;
  }
  .add-customer-label>p{
    font-size: 13px;
  }
  .tableCollection{
    width: 100%!important;
  }
  .searchBar span{
    display: flex;
  }

/*  @media only screen and (max-width: 1300px){
    .tableCollection .ant-select{
        width: 100%;
    }
  }*/
}

.field img {
    width: 40px;
    border-radius: 100%;
    height: 40px;
}

.input-board {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #f0eae7;
    border: 4px dashed #95d472;
    height: 30%!important;
}

.input-board.beige {
    background-color: beige;
}

.input-board .upload-button {
    margin: 10px 0;
}

.input-board .upload-button i {
    margin: 0;
}
.tableCollection table {
    width: 100%;
}

.tableCollection {
    display: block;
    position: relative;
}

.tableCollection table > thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.tableCollection table > thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}

.tableCollection table tbody td{
    border: 1px solid #ccc;
}

.tableCollection table tbody td.rowNumber{
    background-color: #eaf0f5;
    padding: 10px !important;
    text-align: center;
}

.tableCollection table td.left, .tableCollection table td.right {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    width: 10px;
    text-align: center;
}

.tableCollection table td.left span i, .tableCollection table td.right span i {
        color: #8c9cb0;
        font-size: 16px;
}
.tableCollection table td.right span i:hover {
     color: red;
}
.tableCollection table td.right {
    border-left: 1px solid #ccc !important;
    padding-left: 10px;
}

.tableCollection table td.left {
    border-right: 1px solid #ccc !important;
    padding-right: 10px;
}

.tableCollection table td.tableHeader {
    background-color: #f0eae7 !important;
}

.tableCollection.stages table td.tableHeader {
    background-color: #ffffff !important;
}

.tableCollection table td i {
    cursor: pointer;
}

.tableCollection input[type='text'], .tableCollection input[type='number'] {
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
}
.tableCollection input[type='number']  {
    padding: 0 !important;
}

.tableCollection input[type="text"], .tableCollection input[type="password"], .tableCollection input[type="email"], .tableCollection input[type="number"], .tableCollection select.form-control {
    border: 0px solid #d6d6d6;
}
.tableCollection .css-1aya2g8 {
    border-width: 0px;
}

.tableCollection .css-1aya2g8, .tableCollection .css-2o5izw {
    border-radius: 0!important;
}
.tableCollection .css-rsyb7x input {
    min-width: 100px!important;
}
.input-group-addon {
    background-color: transparent!important;
}
.panel-heading input[type="text"]:focus  {
    border-right: 1px solid #44c699!important;
}
.percent-input { 
    border-right: none!important;
}



/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25-Jan-2019, 14:28:13
    Author     : neva
*/
.text-grey {
    color:  #cccbcc!important;
}
.move-right-15 {
    right: 15px;
}
.margin-top-20 {
    margin-top: 20px;
}
td input[disabled] {
    background-color: #ededed!important;
}
.panel-heading {
    text-transform: capitalize;
}
.panel-heading span {
    color: rgb(128, 128, 128);
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: 'ProximaNovaA',sans-serif;
    cursor: pointer;
}
.add-customer-label:hover{
    text-decoration: underline;
    color: #95d472;
}
 .add-customer-label>p {
    display: inline;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    color: #95d472;
    line-height: 1;
    cursor: pointer;
}
 .add-customer-label>i {
    font-size: 21px;
    float: left;
    color: gray;
    margin: 0 10px 0 0;
}
.tableCollection .add-customer-label>i{
    font-size: 15px;
    float: left;
    color: gray;
    margin: 1px 4px 0 0;
}
.panel-heading .no-label {
    padding-right: 10px;
}
.panel-heading .row.bottom {
    padding: 32px 20px 33px 14px;
    background: white;
    margin: 20px -30px -15px -30px;
    font-size: 24px;
}
.panel-heading .row.bottom:before {
    content: url(/static/media/arrow.70e680ec.svg);
    position: absolute;
    margin: -44px 0 0 44%;
}
.panel-heading .row.bottom .total-field {
    font-size: 24px;
}
.panel-heading .row.bottom label {
    font-size: 24px;
}
.billing-data-select .css-va7pk8 {
    font-size: 24px;
}

.meta-data .ant-calendar-picker-input {
    min-width: 100px!important;
}

.panel {
    box-shadow: 0 1px 1px #aaa;
}

.panel.total p.total {
    font-size: 24px;
}

.panel.total {
    min-height: 335px;
}

.transfer   .panel.total {
    min-height: 135px!important;
}

.width-200 input {
    min-width: 200px;
}

input:disabled {
    background: #f8f9fc;
}
#reactForm table tr:focus {
    outline: none;
}

.slide-modal .modalConfirms {
    overflow: auto;
}
.slide-modal .modalConfirms input[type="text"], .slide-modal .modalConfirms input[type="password"], .slide-modal .modalConfirms input[type="email"], .slide-modal .modalConfirms input[type="number"], .slide-modal .modalConfirms select.form-control {
    border: 1px solid #d6d6d6;
    border-radius: 3px;
}
.slide-modal .modalConfirms .css-1aya2g8 {
    border-width: 1px;
}
.total-field, .no-label {
    font-size: 16px;
}
@media only screen and ( max-width: 767px) {
    .panel-heading {
        padding: 10px;
        letter-spacing: -1px;
    }
    .panel-heading .row.bottom {
        margin: 21px -8px -8px -8px;
        padding: 32px 0 33px 14px;
    }
    .panel-heading .no-label {
        padding-right: 0;
    }
    .slide-modal .modalConfirms {
        width: 100% !important;
        overflow: auto;
    }
    .move-right-15 {
    right: 30px;
}
}

.settings_message {
    position: fixed;
    bottom: -20px;
    width: 85%;
    height: 60px;
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}
.settings_message > p {
    background: #44c699;
    margin: 0 auto;
    text-align: center;
    height: 40px;
    font-size: 18px;
    line-height: 2;
    color: white;
    border-radius: 10px 10px 0px 0px;
}

.settings_message > p.error {
    background: #e50000;
}
.dataTable input[type="text"],
.dataTable input[type="number"],
.dataTable .css-1aya2g8{
	margin-bottom: 0;
	border-radius: 0;
	border: none;
}

.dataTable td{
	border: 1px solid #ccc;
}

.dataTable thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.dataTable thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}

.json-builder-table {
    border-collapse: collapse;
    width: 100%;
}

.json-builder-table > tr, .json-builder-table > tr > th, .json-builder-table > tr > td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 24-Apr-2019, 10:11:58
    Author     : neva
*/

input.custom-text {
    background: white;
    height: 100px;
    line-height: 25px;
    margin-top: 10px;
    box-shadow: 0 1px 1px #aaa;
    margin: 30px 0 0 0;
    border-color: #d3d3d3;
}

button[disabled].button-issue {
    background-color: rgba(0,255,0,0.3)!important;
    border: 1px solid rgba(0,255,0,0.3);
}

.form-contacts {
    min-width: unset;
    width: 40%;
    height: 40px;
    margin-right: 10px;
    color: black;
}

.form-stage{
    min-width: unset;
    width: 100%;
    height: 40px;
    color: black;
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
    border-radius: 4px;
}
.stages table tbody td{
    padding: 0px 10px;
}
.form-stage.form-stage-select{
    min-width: 120px;
    border: 1px solid #dbdadb!important;
  }
.contact-icon {
    float: right;
    margin-top: -27px;
    margin-right: -10px;
    cursor: pointer;
}

.add-translations {
    position: absolute;
    left: 48%;
    top: 140px;
}

 i.fa.fa-language {
    cursor: pointer;
}

.sub-document {
     background: #F4F4F4;
}

.main-document {
    font-weight: 600;
}

.panel .panel-body .mobileSelect{
    padding-left: 0px;
}

.set-in-place {
    position: absolute;
    top: 9px;
    left: 100px;
}

.bom-set-in-place {
    position: absolute;
    top: 3px;
    right: 100px;
}

.set-in-place-make-payment {
    position: relative;
    top: 30px;
}
.pay_total .form-group{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}
.pay_total .panel.total {
    border: none;
    box-shadow: none;
    min-height: auto;
  }
.disabled-breadcrumb {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.simple-form-field {
    font-size: 20px;
}

.ant-pagination {
    text-align: center;
    margin-bottom: 10px;
}

.form-input-field .ant-select-selection--single {
    height: 40px;
    border: none;
    border-radius: 0;
}

.form-input-field .ant-select-enabled {
    width: 100%;
}

.help {
    position: relative;
    left: 5px;
}
.ant-calendar-picker-input.ant-input {
    height: 40px;
    margin-bottom: 15px;
}

.multipleSelectList .css-xwjg1b {
    display: none;
}

.collapsible {
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: 1px solid lightgrey;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-radius: 4px;
}

.collapsible:hover {
  background-color: #f1f1f1;
}

.ant-switch-checked{
    background-color: #38b88c!important;
}

.searchItem-table tbody td input[type="number"]{
    min-width: 100px!important;
}

/*.content {
    padding: 35px;
    overflow: hidden;
    background-color: white;
    border: 1px solid lightgrey;
}
*/

.ant-card-extra {
    cursor: pointer;
}

.switch-disabled{
    background-color: #cccccc;
}

.ant-tree li .ant-tree-node-content-wrapper{
    color: #000;
}

.modal .modal-body span {
    float: left;
   /* margin: 0;*/
    line-height: 40px;
    font-size: inherit;
    margin-left: 0px;
}

.status-breadcrumb {
    border: 1px solid #d3d3d3;
}

.forbidden {
    pointer-events: none;
    cursor: not-allowed!important;
    color: lightgrey!important;
}

@media only screen and ( max-width: 767px) {
    .panel .panel-body .mobileSelect{
        float: none;
    }
}

.userProfileInputField{
    margin-left: 0!important;
}

.form-save-button .ant-btn-loading-icon{
    color: #1890ff;
    padding-right: 10px;
}

/*  fix for input overlapping buttons in custom select */
.css-1ep9fjw {
    z-index: 10;
}

.grid-panel a, .newFont, .submenus-link span{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif!important;
}
.ag-font-style {
  user-select: initial!important;
  -moz-user-select: text!important;
  -ms-user-select: text!important;
  -webkit-user-select: text!important;
}
.pagination{
    margin: 0px;
}
.pagination .page-item{
    background-color: white;
    border: 1px solid #1890ff;
    float: left;
    line-height: 1.42857;
    margin-left: 10px;
    padding: 3px 8px;
    position: relative;
    text-decoration: none;
    color: #1890ff;
    border-radius: 4px;
}
.pagination button[disabled]{
    cursor: not-allowed;
    background-color: #f2f2f2 !important;
    border: 1px solid #EFF2F7!important;
    color: #333!important;
}
.paid {
    background-color: #c1f0d1;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #6bc789;
    color: #208052;
    text-transform: capitalize;
}

.unpaid {
    background-color: #ffdeba;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #ec3600;
    color: #ec3600;
    text-transform: capitalize;
}

.partialypaid {
    background-color: #dcddfb;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #4d378f;
    color: #4d378f;
    text-transform: capitalize;
}
.overpaid {
    background-color: #dcddfb;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #4d378f;
    color: #4d378f;
    text-transform: capitalize;
}
.Closed {
    background-color: #c1f0d1;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #6bc789;
    color: #208052;
}
.Closed1{
    background-color: #f0fbf4;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #6bc789;
    color: #208052;
}
.Closed2 {
    background-color: #92e5a0;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #002b0e;
    color: #002b0e;
}
.Closed3 {
    background-color: #D1F4DD;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #6bc789;
    color: #208052;
}
.Closed4 {
    background-color: #72dd84;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #015a1e;
    color: #015a1e;
}
.Closed5 {
    background-color: #66BB6A;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #015a1e;
    color: #ffffff;
}
.Closed6 {
    background-color: #C8E6C9;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #015a1e;
    color: #015a1e;
}
.Closed7 {
    background-color: #AED581;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #1a3100;
    color: #1a3100;
}
.Notstarted {
    background-color: #ffedb7;
    border-radius: 4px;
    padding: 3px 15px;
    color: #e9a80e;
    border: 1px solid #e9a80e;
}
.Finished {
    background-color: #72dd84;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #015a1e;
    color: #015a1e;
}
.Draft {
    background-color: #ffedb7;
    border-radius: 4px;
    padding: 3px 15px;
    color: #e9a80e;
    border: 1px solid #e9a80e;
}
.Draft1 {
    background-color: #fffcf2;
    border-radius: 4px;
    padding: 3px 15px;
    color: #e9a80e;
    border: 1px solid #e9a80e;
}
.Draft2 {
    background-color: #FFF9C4;
    border-radius: 4px;
    padding: 3px 15px;
    color: #e9a80e;
    border: 1px solid #e9a80e;
}
.Draft2 {
    background-color: #ffebab;
    border-radius: 4px;
    padding: 3px 15px;
    color: #876002;
    border: 1px solid #876002;
}
.Draft3 {
    background-color: #FFF9C4;
    border-radius: 4px;
    padding: 3px 15px;
    color: #e9a80e;
    border: 1px solid #e9a80e;
}
.Draft4 {
    background-color: #feffab;
    border-radius: 4px;
    padding: 3px 15px;
    color: #876002;
    border: 1px solid #876002;
  }
.Draft5 {
    background-color: #ffdd70;
    border-radius: 4px;
    padding: 3px 15px;
    color: #291d00;
    border: 1px solid #291d00;
}
.Draft6 {
    background-color: #ffdd70;
    border-radius: 4px;
    padding: 3px 15px;
    color: #10100f;
    border: 1px solid #92908b;
  }
.Archived {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.Archived1 {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.Archived2 {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.Archived3 {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.Archived4 {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.Archived5 {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.Archived6 {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.Archived7 {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.Archived8 {
    background-color: #d7e1ee;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #364a5a;
    color: #364a5a;
}
.New{
    background-color: #bdcbff;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #0c5ad7;
    color: #364a5a;
}
.Awaiting{
    background-color: #d8d800;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #8c5caa;
    color: #364a5a;
}

.Cancelled {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled1 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled2 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled3 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled4 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled5 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled6 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled7 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled8 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Cancelled9 {
    color: #fd2626;
    background-color: #fed3e2;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid #fd2626;
}
.Ontheway {
    background-color: aliceblue;
    border-radius: 4px;
    padding: 3px 15px;
    color: steelblue;
    border: 1px solid dodgerblue;
}
.Ontheway1 {
    background-color: #E3F2FD;
    border-radius: 4px;
    padding: 3px 15px;
    color: #42A5F5;
    border: 1px solid #42A5F5;
}
.Ontheway2 {
    background-color: aliceblue;
    border-radius: 4px;
    padding: 3px 15px;
    color: #01579B;
    border: 1px solid #01579B;
}
.Ontheway3 {
    background-color: #9fcef09c;
    border-radius: 4px;
    padding: 3px 15px;
    color: #0D47A1;
    border: 1px solid #0D47A1;
}
.Inprogress {
        background-color: wheat;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid chocolate;
    color: navy;
}
.Inprogress1 {
    background-color: antiquewhite;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid brown;
    color: black;
}
.Inprogress2 {
    background-color: antiquewhite;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid brown;
    color: black;
}
.Inprogress3 {
    background-color: antiquewhite;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid brown;
    color: black;
}
.Accepted {
background-color: turquoise;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid navy;
    color: black;
}
.Accepted1 {
background-color: aliceblue;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid deepskyblue;
    color: black;
}
.Accepted2 {
background-color: aliceblue;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid deepskyblue;
    color: black;
}
.Accepted3 {
background-color: aliceblue;
    border-radius: 4px;
    padding: 3px 15px;
    border: 1px solid deepskyblue;
    color: black;
}
.New {
    border-radius: 4px;
    padding: 3px 15px;
    color: black;
}
.table-inbox {
    position: relative;
    overflow: scroll;
    margin: 0;
    background: #f8f9fc;
    border-radius: 4px;
    font-size: 14px;
    min-width: 958px;
    height: 63vh;
    margin-top: 15px;
}

.ag-body-viewport {
    overflow-x: scroll;
    overflow-y: scroll !important;
}

.nav.nav-pills li a {
    color: #4eb0e2;
    padding-left: 0;
    padding-top: 5px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'ProximaNovaA', sans-serif;
    float: left;
    position: relative;
    display: block;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    margin-top: 10px;
}
.nav > h3.module-title{
    font-family: ProximaNovaA, sans-serif;
}
.ag-react-container>span>img {
    width: 55px;
}
#ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown{
    top: -10px;
}
#ag-grid-div > div > div:nth-child(6) > div.col-md-12{
    overflow-y: hidden!important;
}
#ag-grid-div > div > div:nth-child(5) > div.col-md-12{
    overflow-y: hidden!important;
}
#ag-grid-div > div > div:nth-child(6) > div.col-md-12 > div{
    overflow-y: hidden!important;
}

.table-inbox {
    overflow: hidden!important;
}
.dropdown.kit-grid-buttons {
    width: auto;
    margin-bottom: -30px;
}
#ag-grid-div .nav-link, #ag-grid-div .nav-link:link, #ag-grid-div .nav-link:visited, #ag-grid-div .nav-link:active {
    font-size: 14px!important;
}
#ag-grid-div .btn-group {
    width: auto;
    position: absolute;
    top: 1px;
    margin-left: 50px;
}
#ag-grid-div .btn-group button {
    border: 1px solid #44c699;
    background: #44c699;
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    text-transform: uppercase;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    cursor: pointer;
    width: 1000px;
}
#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle{
    width: 125px;
}
#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle > .caret{
    margin-left: 10px;
}
#ag-grid-div .btn-group button.btn.btn-success i.ion-plus-round{
    display: none;
}
#ag-grid-div .btn-group button.btn.btn-success.dropdown-toggle:hover {
    text-decoration: none;
    background: #38b88c !important;
}
li.nav-item> div > ul.dropdown-menu {
    background: #fff;
    border-radius: 3px;
    line-height: 1.46667;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    font-family: 'ProximaNovaA', sans-serif;
    color: #444;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
}
li.nav-item> div > ul.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
    color: #333;
}
.nav-link>span>i {
    margin-right: 6px;
}
.dropdown.kit-grid-buttons button {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 10px;
    left: 0;
}

.dropdown.kit-grid-buttons .dropdown-menus {
    top: 30px;
    left: 10px;
}

.dropdown-menus {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px;
    margin: 5px;
    list-style: none;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
}

.deletedButt {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

#ag-grid-div input[type="text"] {

    height: 40px;
    /* line-height: 40px; */
    /* padding: 0 5px; */
    /* float: left; */
    /* border-radius: 4px; */
    /* color: #444; */
    /* font-weight: 300; */
    /* font-size: 16px; */
    background: transparent;
    /* min-width: 320px; */
    /* padding: 10px 0; */
    /* margin-bottom: 15px; */
    outline: 0;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

}

.marg {
    margin: 10px;
    cursor: pointer;
}

.marg-bottom {
    margin-bottom: 10px;
}

.ag-theme-balham .ag-header-cell-resize {
    position: absolute;
    right: -4px;
    width: 8px;
    z-index: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.kit-grid-button {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
}

.kit-grid-but {
    background: transparent !important;
    text-transform: uppercase !important;
    color: #4eb0e2 !important;
    font-weight: bold !important;
    border: none !important;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-color: #b0b5b9;
    border-color: #b0b5b9;
    color: #fff;
}

.btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
}

.dropdown-toggle:focus {
    outline: 0;
}

.btn:hover, .btn:focus {
    color: #333;
    text-decoration: none;
}

.menu {
    z-index: 100;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}
.modalConfirms i.fa.fa-close {
    margin: 10px 15px;
}
.modalConfirms input[type="text"],
.modalConfirms input[type="password"],
.modalConfirms input[type="email"],
.modalConfirms input[type="number"],
.modalConfirms select.form-control,
.modalConfirms .ant-calendar-picker-input{
    min-width: 230px!important;
}

.date-range{
    margin-top: 9px!important;
    margin-bottom: 10px;
    text-align: center;
}

.date-range input {
    cursor: pointer !important;
}

.date-range div {
    border-radius: 4px;
}

.date-range .ant-calendar-picker{
    width: 250px!important;
    min-width: 0px!important;
}

.date-range .ant-calendar-picker-input{
    width: 250px!important;
    min-width: 0px!important;
    font-size: 14px;
    color: rgb(0, 0, 0, 0.65);
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    letter-spacing: normal;
    margin-bottom: 0;
}

.date-range .ant-calendar-range-picker-separator{
    vertical-align: baseline!important;
}

.date-range .ant-calendar-picker-clear,
.date-range .ant-calendar-picker-icon{
    top: 65%;
}

.date {
    margin-top: 12px!important;
    text-align: center;
}

.date .ant-calendar-picker{
    width: 150px!important;
    min-width: 0px!important;
}

.date .ant-calendar-picker-input{
    width: 150px!important;
    min-width: 0px!important;
    font-size: 14px;
    padding-left: 10px!important;
    color: rgb(0, 0, 0, 0.65);
    border: none;
    padding-top: 0;

    padding-left: 21px!important;
    font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    letter-spacing: normal;
}

.date .ant-calendar-picker-clear,
.date .ant-calendar-picker-icon{
    top:37%!important;
}

.modalConfirms input[type="text"],
.modalConfirms input[type="password"],
.modalConfirms input[type="email"],
.modalConfirms input[type="number"],
.modalConfirms select.form-control,
.modalConfirms .ant-calendar-picker-input{
    min-width: 230px!important;
}

.modalConfirms .css-rsyb7x input{
    min-width: 115px!important;
}

.backendropss {
    z-index: 100000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
}

.modalS{
    background-color: #fff;
    border-radius: 5px;
    width: 60%;
    max-height: 90%;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
}
.modalS > div.text-right > div > i{
    color: lightgray;
}

ul li.list {
    margin-left: 15px;
}

#ag-grid-div .dropdown-menu-select {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    /*   display: none;*/
    /* float: left;*/
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
}

/* Navigation */

#ag-grid-div .nav {
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 12px;
    padding-left: 15px;
}

#ag-grid-div .nav-items {
    padding: 0;
    list-style: none;
    float: left;
}

#ag-grid-div .nav-item {
    display: inline-block;
    margin-right: -15px;
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 14px;
}

#ag-grid-div .nav-item >a>i {
    font-size: 20px;
    position: absolute;
}
#ag-grid-div .nav-item.dropdown >a>i {
    top: -26px;
}
#ag-grid-div .nav-item.dropdown.add >a>i {
    top: -2px;
}

#ag-grid-div .nav-item >a>i.ion-plus-round {
    display: none;
}

#ag-grid-div .nav-link,
#ag-grid-div .nav-link:link,
#ag-grid-div .nav-link:visited,
#ag-grid-div .nav-link:active,
#ag-grid-div .submenus-link,
#ag-grid-div .submenus-link:link,
#ag-grid-div .submenus-link:visited,
#ag-grid-div .submenus-link:active {
    display: block;
    position: relative;
    font-size: 16px;
    letter-spacing: 1 px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

#ag-grid-div .submenus-link[disabled] {
    pointer-events: none;
    color: lightgray;
}

.submenus-item.not-allow {
    cursor: not-allowed;
}

#ag-grid-div .nav-link,
#ag-grid-div .nav-link:link,
#ag-grid-div .nav-link:visited,
#ag-grid-div .nav-link:active {
    color: #4eb0e2;
    font-weight: bold;
    /*    text-transform: uppercase;*/
    font-family: 'ProximaNovaA', sans-serif;
    font-size: 12px;
}

#ag-grid-div .nav-link::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 3 px;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translate(0, 10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

#ag-grid-div .nav-link:hover::before,
#ag-grid-div .nav-link:hover::before {
    opacity: 1;
    transform: translate(0, 5px);
}

#ag-grid-div .dropdown {
    position: relative;
}

#ag-grid-div .dropdown .nav-link {
    padding-right: 15px;
    height: 17px;
    line-height: 17px;

}

#ag-grid-div .dropdown .nav-link::after {
    content: "";
    position: absolute;
    top: 6px;
    right: 0;
    border: 5px solid transparent;
    border-top-color: #4eb0e2;
}

.submenus {
    position: absolute;
    top: 140%;
    right: 0;
    z-index: 100;
    max-width: 200px;
    margin-left: -80px;
    background: #fff;
    border-radius: 3px;
    line-height: 1.46667;
    margin-top: -5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    pointer-events: auto;
    display: table;
}
.submenus.changeleft{
    left: 78px!important;
}
.border-del {
    border-top: 1px solid #ccc !important;
    margin: 0;
}

.submenus-items {
    list-style: none;
    padding: 0;
    font-family: 'ProximaNovaA', sans-serif;
    color: #444;
    font-size: 12px;
    width: -webkit-max-content;
    width: max-content;
}

#ag-grid-div .submenus-items .inner-list {
    z-index: 1!important;
    position: absolute;
    left: 100%;
    top: 0px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
}

#ag-grid-div .submenus-items i {
    margin-left: 3px;
    font-size: 12px;
}


.submenus-item {
    display: block;
    text-align: left;

}

.submenus-link,
.submenus-link:link,
.submenus-link:visited,
.submenus-link:active {
    color: #444;
    padding: 8px 20px;
    font-size: 16px;
    font-family: 'ProximaNovaA', sans-serif;
}

.submenus-link:hover {
    text-decoration: underline;
}

.submenus-seperator {
    height: 0;
    margin: 12px 10px;
    border-top: 1px solid #eee;
}

.show-submenus .submenus {
    opacity: 1;
    pointer-events: auto;
}

.submenus-link:hover {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    color: #444;
}

select {
/*    -webkit-appearance: none;
    -moz-appearance: none;*/

}

.selectDots {
    color: #444;
    background: transparent !important;
    border: none !important;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    margin-left: 20px;
}

.option-link {
    color: #444;
    padding: 10px 20px;
    font-size: 12px;
    font-family: 'ProximaNovaA', sans-serif;
    background-color: white !important;
    -moz-appearance: none;
}

ul li ol li {
    list-style: none;
}

li.dropdownCell a {
    margin-left: 10px;
    padding: 10 px;
}

li.dropdownCell:hover {
    background: #f7f7f7;
    text-decoration: none;
    margin-left: 0;
}

.dropdownCellLink {
    margin-left: 10px;
    text-decoration: none;
}

.dropdownCellLink:hover {
    margin-left: 10px;
    text-decoration: none;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: none;
    outline-offset: 0;
}

.DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 80px;
    vertical-align: middle;
    margin-right: 15px;
}

input[type="text"] {
    min-width: 120px;
}

#ag-grid-div .col-xs-1, #ag-grid-div .col-sm-1, #ag-grid-div .col-md-1, #ag-grid-div .col-lg-1,
#ag-grid-div .col-xs-2, #ag-grid-div .col-sm-2, #ag-grid-div .col-md-2, #ag-grid-div .col-lg-2,
#ag-grid-div .col-xs-3, #ag-grid-div .col-sm-3, #ag-grid-div .col-md-3, #ag-grid-div .col-lg-3,
#ag-grid-div .col-xs-4, #ag-grid-div .col-sm-4, #ag-grid-div .col-md-4, #ag-grid-div .col-lg-4,
#ag-grid-div .col-xs-5, #ag-grid-div .col-sm-5, #ag-grid-div .col-md-5, #ag-grid-div .col-lg-5,
#ag-grid-div .col-xs-6, #ag-grid-div .col-sm-6, #ag-grid-div .col-md-6, #ag-grid-div .col-lg-6,
#ag-grid-div .col-xs-7, #ag-grid-div .col-sm-7, #ag-grid-div .col-md-7, #ag-grid-div .col-lg-7,
#ag-grid-div .col-xs-8, #ag-grid-div .col-sm-8, #ag-grid-div .col-md-8, #ag-grid-div .col-lg-8,
#ag-grid-div .col-xs-9, #ag-grid-div .col-sm-9, #ag-grid-div .col-md-9, #ag-grid-div .col-lg-9,
#ag-grid-div .col-xs-10, #ag-grid-div .col-sm-10, #ag-grid-div .col-md-10, #ag-grid-div .col-lg-10,
#ag-grid-div .col-xs-11, #ag-grid-div .col-sm-11, #ag-grid-div .col-md-11, #ag-grid-div .col-lg-11,
#ag-grid-div .col-xs-12, #ag-grid-div .col-sm-12, #ag-grid-div .col-md-12, #ag-grid-div .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
}

.search-field-container {
    position: absolute;
    right: 0;
}

.search-field {
    position: relative;
    float: right;
    background: white;
    min-width: 240px;
    background: #fff;
    height: 40px;
    margin-top: 7px;
}

.search-field i.fa.fa-search {
    position: absolute;
    left: 28px;
    top: 15px;
    color: #a6a6a6;
}
.search-field i.fa.fa-times {
    cursor: pointer;
    position: absolute;
    right: -13px;
    top: 15px;
    color: #a6a6a6;
    font-size: 11px;
}

input.search-field-input {
    float: right !important;
    width: 80% !important;
    border-bottom: 1px solid #32323A!important;
    border-radius: 0!important;
    height: 30px!important;
    padding: 0!important;
}
input.search-field-input:active,
input.search-field-input:focus {
    border-bottom: 2px solid rgb(68, 198, 153)!important;
}

.goto-field {
    background: white;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    height: 40px;
    padding: 0;
    width: 40px;
    margin-top: 10px;
    margin-left: 13px;
}

.DateRangePicker {
    position: relative;
    display: inline-block;
}
.DateRangePickerInput__withBorder {
    border: 0!important;
}
.columns-container {
    margin-right: 30px;
    position: relative;
    display: inline-block;
}

.columns-button {
    margin-left: 0;
    margin-top: 10px;
    background: transparent;
    border-radius: 4px;
    border: none;
    height: 40px;
    line-height: 40px;
}

.columns-button-open {
    cursor: pointer;
/*    padding-left: 22px;*/
}
.columns-button-open > a>i{
    color: rgb(166, 166, 166);
}

.columns-button span {
    margin-left: 5px;
}

.columns-button a {
    padding: 0;
    text-align: center;
    font-size: 14px;
}

.columns-button a:hover, .columns-button a:active {
    text-decoration: none;
}

.grid-settings {
    position: relative;
    top: 19px;
    left: 15px;
}
.grid-settings>i{
    color: rgb(166, 166, 166);
}

.check-fa {
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
}

.button-primary-new {
    text-transform: uppercase;
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 50px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15 px;
    min-width: 125px;
    cursor: pointer;
}

.fontStyle {
    font-family: 'ProximaNovaA', sans-serif;
}

.ag-dnd-ghost {
    display: none !important;
}

.booleanTrue {
    color: green;
}

.booleanFalse {
    color: red;
}

.ag-react-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ag-theme-balham .ag-root {
    border: 0!important;
}
/*Problem with calendar in grids*/
/*.ag-theme-balham .ag-row-selected, .ag-theme-balham .ag-row-hover, .ag-theme-balham .ag-row-focus {
    z-index: 0;
}*/

.grid-overflow-column {
    overflow: visible;
}

.selected-rows-count {
    font-style: italic;
}
.selected-rows-count>sup {
    top: -0.7em;
    margin-left: 5px;
    font-style: normal;
}
.selected-rows-count:hover {
    text-decoration: line-through;
    cursor: pointer;
}
.grid-panel {
    background: white;
    border-radius: 10px;
    width: 99%!important;
    margin: 7px 0 5px 5px;
    padding: 0;
}

.backendropss.transparent-back {
    background-color: transparent;
}
.modalConfirms.scroll.column-selector {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    right: 8px;
    top: 183px;
    width: 18%;
    max-height: 350px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
}
.modalConfirms.scroll.column-selector > section > div > div > ul {
    margin-bottom: 0px;
}
.modalConfirms.scroll.column-selector>div>div>i {
    display: none;
}

.modalConfirms.scroll.column-selector>.newPanel>.panel-body {
    padding-bottom: 0;
    padding-left: 5px;
}
.modalConfirms.scroll.column-selector>.newPanel>.panel-body>row>ul {
    margin-bottom:0;
}
.modalConfirms .panel-body .buttons {
    margin: 10px auto;
}
.column-selector-item,
.column-selector-item>div>ul>li {
    margin-top: -15px;
    min-width: 200px;
}
.checkmark {
    height: 15px;
    width: 15px;
    border-radius: 0;
}
.custom-checkbox {
    padding-left: 25px;
    font-size: 16px;
}
.column-selector-item .custom-checkbox input:checked ~ .checkmark {
    background-color: rgb(68, 198, 153)!important;
    border-radius: 0!important;
}
.custom-checkbox input:checked ~ .checkmark {
    border-radius: 0!important;
}
.custom-checkbox:hover input ~ .checkmark {
    border-radius: 0!important;
}
.custom-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 4px;
    height: 10px;
}

.bulk-container {
    padding-left: 0!important;
    margin-top: 22px;
    width: 10%;
}
.field {
    height: 100px;
}

.ant-calendar-picker, .ant-calendar-picker-input {
    width: 100%!important;
    line-height: 1.5;
}

.ant-tag {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    height: auto;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: default;
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-tag-blue {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
}

.ant-tag, .ant-tag a, .ant-tag a:hover {
    color: rgba(0, 0, 0, 0.65);
}
.downloadIcon{
    position: relative;
    top: 19px;
    margin-left: 20px;
    margin-right: 20px;
}
.downloadIcon i{
    color: rgb(166, 166, 166);
}
.filterIcon{
   position: relative;
   top: 14px;
   right: 0px;
}
.filterIcon img{
   width: 18px;
}

.ant-calendar-header .ant-calendar-prev-year-btn{
    left: 2px;
}

.ant-calendar-header .ant-calendar-next-year-btn{
    right: 0px;
}
/* Notifications */
.top-nav .notif {
    line-height: 25px;
    padding-top: 10px;
    display: table;
    border-bottom: 1px solid #eee;
}
.top-nav .notif i.unread {
    color: #ff6e2c;
}
.notif .notif-edit {
    cursor: pointer;
    display: flex;
    margin: 2px 0;
}
@media only screen and (max-height: 1080px) {
    .table-inbox {
        height: 77vh;
    }
}
@media only screen and (max-height: 900px) {
    .table-inbox {
        height: 74vh;
    }
    .filterIcon{
       margin-bottom: 20px;
    }
}

@media only screen and (max-height: 864px) {
    .table-inbox {
        height: 70vh;
    }
}

@media only screen and (max-height: 800px) {
    .table-inbox {
        height: 71vh;
    }
}

@media only screen and (max-height: 768px) {
    .table-inbox {
        height: 67vh;
    }
}

@media only screen and (max-height: 720px) {
    .table-inbox {
        height: 50vh;
    }
}

@media only screen and ( max-width: 767px) {
/*    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{
        padding-right: 0;
    }*/
    .form-group {
        width: 100%;
        margin-right: 0!important;
        margin-left: 0!important;
    }
    .ag-theme-balham.table-inbox {
        margin-left: 0;
        height: 77vh;
    }
    #ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown{
        position: fixed;
        left: 165px;
        top: 25px!important;
        z-index: 9999;
    }
    #ag-grid-div > div > div.moduleSubMenu > div > nav > ul > li.nav-item.dropdown > a {
        color: white;
    }
    #ag-grid-div > div > div:nth-child(2) > div > nav {
        position: absolute;
        z-index: 99;
        top: -52px;
    }
    #ag-grid-div > div > div.col-md-3.ol-lg-2 > div > nav {
        margin-top: -51px;
        margin-left: 51px;
    }
    .columns-container {
        display: inline-flex;
    }
    .search-field {
        min-width: 225px;
        margin-left: 7px;
    }
    .search-field i.fa.fa-search {
        left: 13px;
    }
    .modalConfirms {
        width: 100%;
    }
    .bulk-container {
        width: auto;
    }
    .nav-items {
        padding-right: 5px;
    }
    .nav-item {
        padding-right: 5px;
    }
    #ag-grid-div .nav-item >a>i.ion-plus-round {
        left: 10px;
        display: block;
    }
    #ag-grid-div .nav-item.dropdown >a>i {
        display: none;
    }
    .columns-button {
        margin-top: 0;
        line-height: 0;
    }
    .search-field-container {
        right: 117px;
        top: 0;
        margin-bottom: 10px;
    }
    .modalConfirms.scroll.column-selector {
        width: 50%;
        top: 125px;
    }
    .column-selector-item, .column-selector-item>div>ul>li {
        margin-top: 25px;
    }
    .custom-checkbox > .checkmark {
        margin-top: -6px;
    }
    .modalConfirms.scroll.column-selector>.newPanel>.panel-body {
        padding-top: 0;
    }
    #main-content.merge-left {
        float: none;
        width: 100%;
    }
    .date-range {
        margin-left: 0px!important;
        position: absolute;
        top: 0;
    }
    .columns-button {
        margin-top: 22px;
    }
    #ag-grid-div .nav-item >a>i.ion-plus-round {
        top: 3px;
        left: 10px;
    }
    #ag-grid-div .btn-group {
        position: relative;
    }
    #ag-grid-div .btn-group button.btn.btn-success {
        width: 50px;
    }
    #ag-grid-div .nav-item.dropdown.add {
        position: absolute;
        top: -12px;
    }
    li.nav-item> div > ul.dropdown-menu {
        margin-left: -80px;
    }

    /*    FORMS*/
    .hide-field {
        display: none;
    }
    #reactForm > div > div > section.panel
    {
        display: block;
        width: 100%
    }
    #reactForm > div > div > section>.panel-body
    {
        display: block;
        width: 100%;
        padding-right: 15px;
    }
    .ant-calendar-picker-input {
        min-width: 200px;
    }
    input[type="number"] {
        min-width: 200px;
    }
    .nav > h3.module-title > span{
        display:none;
    }
    #ag-grid-div .hideMobile {
        display: none!important;
    }

    .nav-items .submenus {
        margin-left: 0;
        left: 0;
    }
}

@media only screen and (min-width: 1536px) {

    input.search-field-input {
        float: right !important;
        width: 80% !important;
    }

    .columns-button {
        margin-left: 0;
        font-size: 12px;
        margin-top: 10px;
    }

    .bulk-container {
        width: 7%;
    }
}

.modalS{
    background-color: #fff;
    border-radius: 5px;
    width: 60%;
    max-height: 90%;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
}
.modalS > div.text-right > div > i{
    color: lightgray;
}
.onboard {
    top: -15px!important;
    bottom: -25px!important;
}
 .onboarding {
    background: linear-gradient(to bottom, rgb(0, 220, 174) 0%, rgb(3, 183, 210) 100%);
    border-radius: 0px;
    padding-left: 0;
    padding-right: 0;
    overflow: visible;
    display: table;
}
.onboarding h2, .onboarding h4 {
    color: white;
}
.onboarding select {
    background-color: white;
    cursor: pointer;
}
.onboarding-selectable {
/*    margin-top: 20px;*/
}
.onboarding-modules{
    background-color: #fff;
    width: 100%;
    margin-top: 15px;
}
.onboarding-modules h4, h6 {
    color: black;
}
.onboarding-modules h6 {
    font-size: 14px;
}
.onboarding-modules-all {
    margin-top: 20px;
    margin-left: 20px;
}
#initial-tax, #initial-currency {
    width: 77%;
    min-width: 100px!important;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgb(166, 166, 166);
    float: right;
}
.onboarding-modules-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 8.5px 0 8.5px;
    border-color: #1BD0C0 transparent transparent transparent;
    margin: 0 auto;
}
.onboarding-line-module {
    width: 15%;
    display: inline-block;
    padding: 19px 9px;
    border: 2px solid lightgrey;
    color: lightgray;
    margin-right: 32px;
    margin-bottom: 14px;
    cursor: pointer;
}
.onboarding-line-module > p {
        margin: 0;
}
.onboarding-line-module span{
    font-size: 17px;
    position: absolute;
    left: 99px;
    color:  #44c699;
}
.onboarding-line-module.checked{
    color:  #44c699;
    border: 2px solid  #44c699;
    background-color: #f8f8f8;
}
.onboarding-line-module.checked:after{
    content:'✓';
    position: relative;
    float: right;
    margin-top: -52px;
    font-size: 22px;
}
.onboarding-demo {
    background-color: white;
    margin-top: 8px;
    border-radius: 6px;
    box-shadow: 2px 2px 9px #888888;
    padding: 20px;
}
.onboarding-demo p {
    margin-left: 30px;
    font-size: 16px;
    color: black;
    margin-top: 15px;
}

.onboarding-modules-all > div > label {
    margin-top: 11px;
}

.dot {
  height: 35px;
    width: 35px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin-left: -2px;
    margin-right: -2px;
    padding: 5px;
    color: rgb(38, 213, 179);
    font-size: 20px;
    font-weight: bold;
}
.rectangle {
  height: 4px;
    width: 123px;
    background-color: white;
    display: inline-block;
    margin-bottom: 4px;
}
.links {
    color: #2a6496;
    font-weight: bold;
}
.links:hover {
    color: #2a6496;
}
.textarea-notif {
        color: black;
    }
.fa.fa-close.notif {
        color: black;
    }

.modalConfirm{
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
    padding: 30px;
    overflow-y: scroll;
}

.read-more{
    display: block;
    margin-top: 6px;
    font-weight: bold;
}
.read-more a{
    color: #3E86B0;
}
.date_r {
    font-style: italic;
    margin-top: 15px;
    margin-left: -5px;
}
.date_r img{
    width: 20px;
    border-radius: 50%;
    height: 20px;
}
.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}
.backendropss {
    z-index: 1000!important;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0,0,0,0.4);
     width: 100%;
}
.posPage .backendropss{
    top: 0!important;
}
.newPanel{
    padding: 0;
    display: table;
    width: 100%;
    /*margin-top: 15px;*/
}
.button-outlines{
    border: 1px solid;
    border-radius: 3px;
    height: 35px;
    float: inherit;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 100px;
    cursor: pointer;
    background: transparent;
}

.button-primary-edited {
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    float: inherit;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;
}

.crmActivities{
    margin-top: 30px;
}
p.pull-left > sup {
    margin-left: 6px;
    font-size: 15px;
}
p.pull-left > sup >i{
    transform: rotate(-45deg);
}

.button-primary {
    font-family:  'ProximaNovaA',sans-serif;
    font-size: 14px;
}
.act-date{
    float: right;
    margin-right: 50px;
}
.type i.fa {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #EFEFEF;
  padding: 0.5em 0.6em;
  background: #EFEFEF;
  color: #ACAAAD;
}
.type {
       margin-left: -5px;
}

@media only screen and (max-width: 1152px) {
 .modalS{
    background-color: #fff;
    border-radius: 5px;
    width: 80%;
    height: 90%;
    max-height: 90%;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 30px;
    overflow-y: auto;
}

.modalConfirm{
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
    padding: 30px;
    overflow-y: scroll;
}

.button-outlines{
    border: 1px solid;
    border-radius: 3px;
    height: 35px;
    float: inherit;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 50px;
    cursor: pointer;
    background: transparent;
}

.button-primary-edited {
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    float: inherit;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 50px;
    cursor: pointer;
}

.newPanel{
    padding: 0;
    display: table;
    width: 100%;
}
}

input[type="text"] {
    width: 100%;
}

textarea{
    min-width: 320px;
    min-height: 140px;
    resize: vertical;
    width: 100%;
}

.css-1rtrksz {
    padding: 0 7px!important;
}
.css-2o5izw:hover {
    border: 1px solid #44c699!important;
    box-shadow: 0 0 2px 0 rgba(68, 198, 153, 0.3)!important;
}

.css-2o5izw:focus, .css-2o5izw:not(:focus) {
    border: 1px solid #44c699!important;
    box-shadow: 0 0 2px 0 rgba(68, 198, 153, 0.3)!important;
}
.css-bl6clz{
    border: none!important;
    border-left: none!important;
    border-right: none!important;
}
.css-xwjg1b {
    text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
    textarea{
        min-width: 180px;
        min-height: 140px;
        resize: vertical;
        width: 100%;
    }
    .type {
        font-size: 9px;
        line-height: 22px;
    }
    .backendropss {
        top: 93px;
        z-index: 2000!important;
    }
    .modalS {
        width: 100%;
        height: 90%;
        max-height: 90%;
        overflow-y: auto;
    }
    textarea {
        border: 1px solid grey;
    }
    .onboarding-line-module {
        width: 37%;
        padding: 19px 3px;
    }
    .rectangle{
        width: 70px;
    }
    #initial-tax, #initial-currency {
        float: none;
        width: 80%;
    }
}
.p_wrap {
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 16px;
  }

.react-datepicker-ignore-onclickoutside{
    width: 100%;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1em;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 0.8em;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 1em;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    text-align: center;
    cursor: pointer;
    position: absolute;
    width: 0;
    padding: 0;
    z-index: 1;
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
}

.react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__month {
    margin: 0.4em 1em;
    text-align: center;
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 70px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 70px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 30px;
    width: 100%;
    box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    text-align: center;
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}

.react-datepicker__day {
    cursor: pointer;
}

.react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day--today {
    font-weight: bold;
}

.react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}

.react-datepicker__day--highlighted:hover {
    background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1 {
    color: magenta;
}

.react-datepicker__day--highlighted-custom-2 {
    color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
    background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
}

.react-datepicker__day--keyboard-selected:hover {
    background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #f0f0f0;
    color: #000;
}

.react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
}

.react-datepicker__day--disabled:hover {
    background-color: transparent;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;
}

.react-datepicker__close-icon::after {
    background-color: #216ba5;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft{
    z-index: 10001;
}
.crm-profile .account-profile-information-general{
  position: relative;
   z-index: 20;
 }
 .account-profile-tabTable{
    color: black;
 }
.crm-profile .account-profile-tabs .ant-tabs .ant-tabs-small-bar .ant-tabs-tab, .crm-profile .account-profile-tabs .ant-menu-submenu-title{
    padding: 8px 16px;
    background: transparent;
    border: 0;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 3px;
}
.crm-profile .account-profile-tabs .ant-tabs-nav .ant-tabs-tab-active, .crm-profile .account-profile-tabs .ant-menu-submenu-active, .crm-profile .account-profile-tabs .ant-menu-submenu-title:hover{
    color: #4eb0e2;
}
.crm-profile .account-profile-tabs .ant-tabs-ink-bar{
    background-color: #4eb0e2;
}
.crm-profile .account-profile-tabs .ant-table-row-cell-break-word{
    word-break: inherit;
    overflow-wrap: normal;
}
.crm-profile .account-profile-tabs .ant-table{
  font-size: 15px;
}
.crm-profile .crmActivities ul li .conversation-text.no-activities{
      border: none!important;
}
.crm-profile  .crmActivities ul li label{
   margin-right: 5px;
 }
.crm-profile  .crmActivities .static-buttons{
  top: 0;
}
.crm-profile .crmActivities .tread-btn{
    border: 1px solid #babab7;
    padding: 5px 7px;
    border-radius: 4px;
    margin-right: 50px;
}
.crm-profile .crmActivities .tread-btn a{
  text-decoration: none;
}
.crm-profile .crmActivities .tread{
    float: right;
    border:1px solid lightgray;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
  }

.crm-profile .account-profile-tabs .ant-tabs-bar {
    margin: 0px;
  }
.crm-profile .account-profile-tabs .ant-menu-horizontal {
    line-height: 25px;
  }
.crm-profile .account-profile-tabs .btn-round{
   top: 0px;
   right: 20px;
   z-index: 1;
}
.crm-profile .account-profile-tabs .btn-round .ion-android-add {
    line-height: 50px;
    text-align: center;
    color: white;
    font-size: 30px;
 }
.inlineHover > i {
    margin-right: 5px;
    margin-left: 2px;
    font-size: 15px;
    cursor: pointer;
}
.inlineHover.input-select2 > i.icon.ion-checkmark-round,
.inlineHover.input-select2 > i.fa.fa-times-circle-o {
    position: relative;
    top: 8px;
}
.inlineHover > i.icon.ion-edit,
.inlineHover > i.icon.ion-android-delete {
    visibility: hidden;
}
.inlineHover > .icon.ion-checkmark-round {
    margin-left: 5px;
}

.inlineHover:hover > i.icon.ion-edit,
.inlineHover:hover > i.icon.ion-android-delete {
    visibility: visible;
    cursor: pointer;
}

.inlineHover > i.icon.ion-edit {
    font-size: 14px;
}
.inlineHover > i.icon.ion-checkmark-round {
    color: #44c699;
}

.inlineHover > i.icon.ion-edit {
    color: #d3d3d3;
}

.inlineHover > i.icon.ion-android-delete {
    color: #d3d3d3;
}

.inlineHover > i.fa.fa-times-circle-o {
    color: #d3d3d3;
}

.inlineHover .tags{
    word-break: normal;
    display: block;
}

.inlineHover .tags li{
    display: inline-block;
    margin-bottom: 5px;
}

.inlineHover>input {
    padding-right: 0px !important;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #44c699;
    padding-left: 0px !important;
    margin-right: 10px;
    letter-spacing: 0;
}

.inlineHover>input:focus {
    border: none;
    border-bottom: 1px solid #44c699;
    box-shadow: none;
}
.inlineHover>input[type=date] {
    line-height: 16px;
    border: none;
    cursor: pointer;
}

.inlineHover>[type=date] {
    background:#fff;
}

.inlineHover>select {
    border-radius: 6px;
    background: transparent;
    width: 80%;
    height: auto;
    -webkit-appearance: menulist-button;
}
.inlineHover>[type=date]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}
.inlineHover>[type=date]::-webkit-clear-button
{
    color: #a0a0a0;
    font-size: 12px;
}
.inlineHover>[type=date]::-webkit-calendar-picker-indicator {
    color: #a0a0a0;
    font-size: 14px;
}

.inlineHover .icon-before-input {
    margin-right: 7px;
}

.inlineHover .icon-before-input i{
    color: #a0a0a0;
}

.inlineHover .button.green {
    font-size: 14px;
    margin-left: 10px;
}
#comments {
    padding: 10px !important;
}

#comments input {
    height: 40px;
}

#comments input[type='text'] {
    width: 80%;
    min-width: 190px!important;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
}

#comments input[type='button'] {
    width: 20%;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #44c699;
    color: #fff;
}

#comments .single-message {
    margin-top: 30px;
}

#comments .image-container {
    padding: 0;
    text-align: right;
}

#comments .single-message img {
    width: 30px;
    border-radius: 50%;
    height: 30px;
}

.content-container {
    padding-left: 0;
}

#comments .content-container h3 {
    text-align: center;
}


#comments .content-container .user-comments:nth-child(2) .comment-content {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    word-wrap: break-word;
}

#comments .content-container .user-comments:last-child .comment-content {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    word-wrap: break-word;
}

#comments .single-message .author {
    font-weight: bold;
    text-align: center;
}

#comments .single-message .publish-date {
    font-weight: bold;
    text-align: right;
    color: #ccc;
    width: 100%;
    padding-right: 15px;
}

#comments .single-message .comment-content {
    padding: 10px;
    background: #fbfafb;
    border: 1px solid #e3e3e3;
    color: black;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
    word-wrap: break-word;
}

#comments .single-message .comment-content .comment-actions {
    text-align: right;
}

#comments .comment-actions a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}

.modalConfirms .panel-body {
    width: 90%;
}

.modalConfirms .panel {
    border: 0;
    box-shadow: none;
}

.modalConfirms header {
    display: none!important;
}
.account-profile .account-profile-information .ant-card-head-title{
    font-size: 18px;
}
.account-profile .line span{
    color: black;
    font-size: 16px;
}
.account-profile .content .notes p{
    font-size: 18px;
}
.account-profile .account-profile-card-header{
    font-size: 16px;
}
.account-profile .account-profile-popup-form label{
    font-size: 16px;
}
.line-address>i.icon {
    visibility: hidden;
    margin-right: 8px;
}

.line-address:hover > i.icon {
    visibility: visible;
    font-size: 15px;
    cursor: pointer;
}
.line-address {
    justify-content: right!important;
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 12px;
    margin-right: 25px;
}
.line-note {
    justify-content: right!important;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 12px;
    margin-right: 25px;
}
.line input {
    min-width: 40px!important;
}
.btn-crm {
    float: none;
}

.crm-profile .account-profile-other-information-more{
    position: relative;
/*    z-index: 0;*/
}

.crm-profile .account-profile-information.comment{
    z-index: 8;
}

.account-profile-other-information textarea {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(202, 202, 201);
    border-radius: 3px;
    box-shadow: none;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 140px;
}

.add-contact {
    font-weight: bold!important;
}

.add-contact>i {
    margin-right: 7px;
}

.add-contact:hover,  .add-contact:active, .add-contact:focus{
    text-decoration: none;
    color: #110872;
}

.infoMessage{
   margin: 20px 0px;
   padding-left: 0px;
   font-size: 16px;
}

.table .btn-group .fa-print{
    padding-bottom: 6px;
}
.callIcons span.circle{
    height: 25px;
    width: 25px;
    background-color: #74c15e;
    border-radius: 50%;
    display: inline-block;
    color: white;
    text-align: center;
    line-height: 26px;
    cursor: pointer;
    font-size: 12px;
    margin-right: 10px;
  }
.callIcons span.circle a{
      color: white;
    }

 .item-profile-right > div {
          margin-top: 0px!important;
      }

.custom-data.ant-tabs{
    overflow: visible!important;
    padding-top: 60px;
}
.newProjectsButton{
    background: #4eb0e2!important;
    width: 25px!important;
    height: 25px!important;
    padding: 0!important;
    border-radius: 100%!important;
    line-height: 18px!important;
    color: white!important;
    border: 0!important;
    margin-left: 14px!important;
    font-size: 18px!important;
}
@media only screen and (max-width: 900px) {
  .line.line-accountContact{
    justify-content: flex-start!important;
  }
}

.pointer {cursor: pointer;}
.line.line-accountContact{
  border-radius: 4px;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px;
  margin: 15px;
}
.line-accountContact .contactName{
    font-weight: 600;
}
i.contactDelete :hover{
    color: red !important;
    font-weight: bold !important;
    outline: none;
}

.project:hover + .project-hint{
  display: table;
}

.mySelect__value-container{
    width: 60%;
   }

   .button-primary-ant {
    border: 1px solid #44c699 !important;
    background: #44c699 !important;
    color: #fff !important;
    min-width: 125px !important;
   /* border-radius: 3px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;*/
   }

.crm-profile .dnd-icon{
    color: #e50000;
}
.swatch {
    margin-left: 5px;
    padding: 0;
    background: #fff;
    border-radius: 2px; 
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
}
.color {
    width: 48px;
    height: 22px;
    border-radius: 2px; 
}
.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.popUpColor {
    position: absolute;
    z-index: 2;
}

input {
    padding-right: 0px !important;
    padding-left: 3px !important;
}

.result {
    margin: 10px 5px;
    width: 20%;
    height: 32px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    border-radius: 3px;
    font-size: 16px;
}
.saveColor {
    display: inline-block;
    margin: 0 0 5px 15px;
}
.activity-calendar{
	margin: 0 0px 20px 0px;
}

.activity-calendar .dashboard{
	margin-bottom: 20px;
}
.activity-calendar .taskTitle{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 25px;
}

.activity-calendar .dashboard .dropdown-menu{
	position: absolute;
	left: 260px;
	font-size: 16px;
}

.activity-calendar .calendar{
	 height: 800px;
	 background-color: #fff;
	 padding: 30px;
}

.activity-calendar .calendar-toolbar{
	display: flex;
	margin-bottom: 20px;
}

.activity-calendar .calendar-toolbar .items{
	margin-left: 20px;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
}
.activity-calendar .calendar-toolbar .items:first-child{
	margin-left: 0px;
}
.activity-calendar .calendar-toolbar .items.itemActive{
		color: #4eb0e2;
    border-bottom: 2px solid #4eb0e2;
		padding-bottom: 5px;
	}
.activity-calendar .calendar-toolbar .nextMonth{
	margin-left: 20px;
	font-size: 16px;
}
.activity-calendar .calendar-toolbar .currentMonth h2{
	margin: 0px 0px 15px 0px;
}

.activity-calendar .calendar-toolbar .currentMonth h2::first-letter {
  text-transform: uppercase;
}

.activity-calendar .filters{
	position: absolute;
	right: 20px;
	cursor: pointer;
	font-size: 16px;
	margin-right: 10px;
}
.activity-calendar .filters img{
	width: 15px;
	margin-right: 5px;
}
.activity-calendar .calendar .activeFilters{
    right: 20px;
    top: 60px;
		font-style: italic;
		margin: 10px 10px 10px 0px;
}
.activity-calendar .filter-type{
	margin-bottom: 30px;
}

.activity-calendar .filter-type input{
	margin-right: 7px;
}
.activity-calendar .filter-type input[type="text"]{
	max-width: 180px!important;
}
.activity-calendar .filter-type #eventsAll, .activity-calendar .filter-type #employeesAll{
	margin-left: 50px;
}
.activity-calendar .panel-body .filter-type .filterLabels{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
    letter-spacing: .7px;
    font-size: 15px;
    text-transform: capitalize;
}
.activity-calendar .panel-body .filter-type label{
	 color: inherit;
}
.activity-calendar .panel-body .filter-type h3{
		padding: 0px 0 10px 0;
    font-size: 22px;
	}
.activity-calendar .newPanel .panel-body .buttons{
	margin: 0px;
}
.activity-calendar .newPanel .panel-body .resultsLabelDate{
	font-size: 16px;
	color: #000;
}
.activity-calendar .newPanel .panel-body .resultsLabelInfo{
	font-weight: bold;
}
.activity-calendar .newPanel .panel-body span.resultsLabel{
	 font-weight: normal;
}
.activity-calendar .newPanel .panel-body #action-menu {
    cursor: pointer;
    font-size: 24px;
    color: lightgray;
}
.activity-calendar .newPanel .panel-body #action-menu i {
    float: right;
}
.activity-calendar .newPanel .panel-body #action-menu:hover .account-action {
    display: block;
}
.activity-calendar .newPanel .panel-body .account-action{
	  position: inherit;
    right: 7px;
    width: 5%;
    float: right;
    border: 1px solid lightgray;
    background: white;
    border-radius: 2px;
    box-shadow: 0 0 7px 0 #d4d4d4;
    font-size: 16px;
    font-style: normal;
    z-index: 1039;
    top: 22px;
    display: none;
    min-width: 140px;
}
.activity-calendar .newPanel .panel-body .account-action li a {
    display: list-item;
    text-decoration: none;
    list-style: none;
    padding: 10px 15px 10px 15px;
}
.activity-calendar .newPanel .panel-body .status-breadcrumb{
    border: 1px solid #d6d6d6;
	}
.activity-calendar .newPanel .panel-body .status-container{
		padding: 0px 30px 0px 40px;
}
.activity-calendar .moduleSubMenu .button-primary i{
	 display: none;
}
.activity-calendar .rbc-ellipsis, .activity-calendar .rbc-event-label, .activity-calendar .rbc-row-segment .rbc-event-content, .activity-calendar .rbc-show-more{
	 font-weight: 700;
	 font-size: 15px;
}
.rbc-calendar{
	height: 90%;
}
@media (min-width: 1600px){
	    display: block;
	.activity-calendar .newPanel .panel-body .resultsLabelDate{
		font-size: 20px;
	}
	.activity-calendar .newPanel .panel-body .resultsLabelInfo{
		font-size: 18px;
	}
	.activity-calendar .newPanel .panel-body span.resultsLabel{
		font-size: 18px;
                margin-right: 14px;
	}
}
@media only screen and ( max-width: 1100px) {
	.activity-calendar .calendar-toolbar{
		display: block;
	}
	.activity-calendar .calendar .activeFilters{
		top: 68px;
	}
	.activity-calendar .calendar-toolbar .nextMonth{
	  display: none;
	}
}
@media only screen and ( max-width: 767px) {
	.activity-calendar .newPanel .panel-body .status-container{
		padding: 0px;
    margin-left: 15px;
	}
	.activity-calendar .moduleSubMenu .button-primary i{
		display: block;
	}
	.activity-calendar .calendar-toolbar .items{
		font-size: 14px;
	}
	.activity-calendar .calendar-toolbar .currentMonth h2 {
    margin-top: 30px;
  }
	.activity-calendar .dashboard .dropdown-menu {
	 margin-left: -80px;
	 position: fixed;
	 top: auto;
	 left: 56%;
	 bottom: 0px;
	 z-index: 999;
 }
 .activity-calendar .filters{
	 top: 38px;
	 z-index: 999;
 }
 .activity-calendar .newPanel .panel-body .row.m-bot15{
    margin-left: 0px;
    margin-right: 0px;
  }
	.activity-calendar .filter-type{
	margin-top: 30px;
	display: grid;
  }
  .activity-calendar .filter-type #eventsAll, .activity-calendar .filter-type #employeesAll{
	margin-left: 0px;
}
	.activity-calendar .calendar .activeFilters{
		top: 0px;
		max-width: 80%;
		position: relative;
		margin-left: 20px;
	}
	.rbc-month-view{
	  height: 85%;
	}
}

.inlineHover > i {
    margin-right: 5px;
    margin-left: 2px;
    font-size: 15px;
    cursor: pointer;
}
.inlineHover.input-select2 > i.icon.ion-checkmark-round,
.inlineHover.input-select2 > i.fa.fa-times-circle-o {
    position: relative;
    top: 8px;
}
.inlineHover > i.icon.ion-edit,
.inlineHover > i.icon.ion-android-delete {
    visibility: hidden;
}
.inlineHover > .icon.ion-checkmark-round {
    margin-left: 5px;
}

.inlineHover:hover > i.icon.ion-edit,
.inlineHover:hover > i.icon.ion-android-delete {
    visibility: visible;
    cursor: pointer;
}

.inlineHover > i.icon.ion-edit {
    font-size: 14px;
}
.inlineHover > i.icon.ion-checkmark-round {
    color: #44c699;
}

.inlineHover > i.icon.ion-edit {
    color: #d3d3d3;
}

.inlineHover > i.icon.ion-android-delete {
    color: #d3d3d3;
}

.inlineHover > i.fa.fa-times-circle-o {
    color: #d3d3d3;
}

.inlineHover .tags{
    word-break: normal;
    display: block;
}

.inlineHover .tags li{
    display: inline-block;
    margin-bottom: 5px;
}

.inlineHover>input {
    padding-right: 0px !important;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #44c699;
    padding-left: 0px !important;
    margin-right: 10px;
    letter-spacing: 0;
}

.inlineHover>input:focus {
    border: none;
    border-bottom: 1px solid #44c699;
    box-shadow: none;
}
.inlineHover>input[type=date] {
    line-height: 16px;
    border: none;
    cursor: pointer;
}

.inlineHover>[type=date] {
    background:#fff;
}

.inlineHover>select {
    border-radius: 6px;
    background: transparent;
    width: auto;
    height: auto;
    -webkit-appearance: menulist-button;
    width: 80%;
}
.inlineHover>[type=date]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}
.inlineHover>[type=date]::-webkit-clear-button
{
    color: #a0a0a0;
    font-size: 12px;
}
.inlineHover>[type=date]::-webkit-calendar-picker-indicator {
    color: #a0a0a0;
    font-size: 14px;
}

.inlineHover .icon-before-input {
    margin-right: 7px;
}

.inlineHover .icon-before-input i{
    color: #a0a0a0;
}

.inlineHover .button.green {
    font-size: 14px;
    margin-left: 10px;
}
#comments {
    padding: 10px !important;
}

#comments input {
    height: 40px;
}

#comments input[type='text'] {
    width: 80%;
    min-width: 190px!important;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
}

#comments input[type='button'] {
    width: 20%;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #44c699;
    color: #fff;
}

#comments .single-message {
    margin-top: 30px;
}

#comments .image-container {
    padding: 0;
    text-align: right;
}

#comments .single-message img {
    width: 30px;
    border-radius: 50%;
    height: 30px;
}

.content-container {
    padding-left: 0;
}

#comments .content-container h3 {
    text-align: center;
}


#comments .content-container .user-comments:nth-child(2) .comment-content {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    word-wrap: break-word;
}

#comments .content-container .user-comments:last-child .comment-content {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    word-wrap: break-word;
}

#comments .single-message .author {
    font-weight: bold;
    text-align: center;
}

#comments .single-message .publish-date {
    font-weight: bold;
    text-align: right;
    color: #ccc;
    width: 100%;
    padding-right: 15px;
}

#comments .single-message .comment-content {
    padding: 10px;
    background: #fbfafb;
    border: 1px solid #e3e3e3;
    color: black;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
    word-wrap: break-word;
}

#comments .single-message .comment-content .comment-actions {
    text-align: right;
}

#comments .comment-actions a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}

.modalConfirms .panel-body {
    width: 90%;
}

.modalConfirms .panel {
    border: 0;
    box-shadow: none;
}

.modalConfirms header {
    display: none!important;
}

.line-address>i.icon {
    visibility: hidden;
    margin-right: 8px;
}

.line-address:hover > i.icon {
    visibility: visible;
    font-size: 15px;
    cursor: pointer;
}
.line-address {
    justify-content: right!important;
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 12px;
    margin-right: 25px;
}
.line-note {
    justify-content: right!important;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 12px;
    margin-right: 25px;
}
.line input {
    min-width: 40px!important;
}
.btn-crm {
    float: none;
}

.invoice-profile-other-information textarea {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(202, 202, 201);
    border-radius: 3px;
    box-shadow: none;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 140px;
}

.add-contact {
    font-weight: bold!important;
}

.add-contact>i {
    margin-right: 7px;
}

.add-contact:hover,  .add-contact:active, .add-contact:focus{
    text-decoration: none;
    color: #110872;
}

.infoMessage{
   margin: 20px 0px;
   padding-left: 0px;
   font-size: 16px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information i{
    top: -23px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more{
    padding-bottom: 20px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment{
    position: relative;
    top: -50px;
    left: 400px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment i{
    color: white;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment .btn-round{
    position: absolute;
    width: 20px;
    height: 20px;
    font-size: 15px;
    line-height: 23px;
    right: 250px;
    top: 20px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment .dropdown-menu{
    top: 40px;
    left: 100px;
}

.invoice-profile .account-profile .line .title{
    font-weight: bold;
}

.invoice-profile .account-profile .line a.link{
    margin-left: 6px;
    font-size: 20px;
}

.invoice-profile .account-profile .line .payment-status span.unpaid{
    color: #ec3600;
}
.invoice-profile .account-profile .line .payment-status span.paid{
    color: #208052;
}
.invoice-profile .account-profile .line .payment-status span.partialypaid{
    color: #4d378f;
}
.invoice-profile .account-profile .line .invoice-no{
    margin-bottom: 10px;
}

.invoice-profile .account-profile .line .amount span,
.invoice-profile .account-profile .line .due span
{
    color: #716f6f;
    font-weight: bold;
}

.invoice-profile .account-profile .line span {
    color: #464646;
}
.invoice-profile .account-profile .actions{
    margin-bottom: 10px;
}

.invoice-profile .account-profile .actions .template-options span{
    text-transform: uppercase;
    margin-right: 10px;
}
.invoice-profile .account-profile .actions span{
    margin-right: 40px;
}

.invoice-profile .account-profile .actions span i{
    margin-left: 7px;
}

.invoice-profile .account-profile .actions span a{
    margin-left: 6px;
    color: #4eb0e2;
    text-decoration: none;
    text-transform: uppercase;
}

.invoice-profile .account-profile .line .dueDate{
  border: 1px solid #ffdb9a;
  margin-left: 15px;
  padding: 6px;
  font-size: 12px;
  border-radius: 4px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .payment-form i{
    top:25px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment .btn-round button{
    background: transparent;
    border: none;
    right: 10px;
    top: -4px;
    font-size: 15px;
}

.general.item-profile-left .content span {
    color: inherit;
}
.item-profile-left .item-title{
    font-size: 26px;
    margin-top: 20px;
}
.item-profile-left .item-picture{
    height: 70px!important;
    margin: 10px 0;
    border: 1px solid lightgray;
    border-style: dotted;
    border-radius: 2px;
    display: flex;
    align-items: center;
}
.item-profile-left .item-picture img{
    width: 100%;
    max-width: 70px!important;
    max-height: 70px!important;
    padding: 5px;
}
.item-profile .account-profile .line .title .help{
    left: 0px;
}
.itemHistory-table{
    border: 1px solid lightgray;
    background: white;
    border-radius: 2px;
    box-shadow: 0 0 7px 0 #d4d4d4;
}
.ant-form-inline .ant-form-item {
    float: right;
}
.ant-table-title {
    margin-left: 16px;
}
.ant-table-pagination.ant-pagination {
    padding-right: 16px;
}
.item-profile .itemHistory-table .ant-table {
      color: inherit;
      overflow: auto;
}
.item-profile .account-profile .account-profile-tabs .nav.nav-tabs div {
    margin-top: 0px;
}
.itemHistory-table.ant-table-row-cell-break-word {
    word-break: inherit;
}
.item-profile .ant-table-row-cell-break-word {
    word-break: inherit;
}
.item-profile .account-profile .account-profile-tabs {
    border: none;
    background: transparent;
    margin-top: 10px;
    padding: 0;
    box-shadow: none;
    border-bottom: transparent;
}
.item-profile .account-profile .account-profile-tabs .nav.nav-tabs {
    background: none;
}
.item-profile .account-profile .account-profile-tabs .btn-tabs{
  border-bottom: transparent;
}
.item-profile .account-profile .account-profile-tabs .btn-tabs.active {
    border-bottom: 2px solid #4eb0e2!important;
}
.ion-eye {
    cursor: pointer;
}
.itemStatus-active{
    margin-right: 10px;
    background-color: #ccffcc;
    position: relative;
    border-radius: 15px;
    text-align: center;
    color: #30a15d;
    padding: 1px 10px;
    font-weight: bold;
}
.itemStatus-disable{
    margin-right: 10px;
    background-color: #ffdcd2;
    position: relative;
    border-radius: 15px;
    text-align: center;
    color: #ea2c2b;
    padding: 1px 10px;
    font-weight: bold;
  }
/* SalesProfile*/
.document-template{
  position: absolute;
  right: 25px;
  top: -49px;
  display: flex;
  align-items: center;
}
.onlineOrder-status{
    border: 1px solid #dce0e8;
    border-radius: 6px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
    background: white;
    text-align: center;
    margin-top: 10px;
}
.onlineOrder-status .container-status{
  padding: 20px 0;
}
.onlineOrder-status .payment-bg{
    background-image: linear-gradient(-180deg,#fff 0%,#fff1dc 150%);
    border-radius: 6px;
}
.onlineOrder-status .delivery-bg{
    background-image: linear-gradient(-180deg,#fff 0%,#e8fbff 150%);
    border-radius: 6px;
}
.onlineOrder-right-side .ant-card{
    border: 1px solid #dce0e8;
    margin-top: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
    background: white;
  }

  @media only screen and (max-width: 780px) {
    .sale-tabs {
        display: grid;
    }

    .sale-tabs .btn-tabs{
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .sale-tabs .btn-tabs.active{
        border: 2px solid #4eb0e2!important;
    }
  }
  @media only screen and (max-width: 900px) {
    .item-profile .account-profile {
        padding-left: 10px;
        padding-right: 10px!important;
    }
    .item-profile-right .actions{
        margin: 10px 0px;
    }
    .item-profile-right .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 4px!important;
    }
    .item-profile .account-profile .account-profile-tabs .btn-tabs{
        font-size: 14px;
        margin-left: 0px;
        padding: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .document-template {
        position: initial;
      }
  }


  .single-chart {
    width: 98%;
    justify-content: space-around ;
  }
  
  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 150px;
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  .circular-chart.orange .circle {
    stroke: #ff9f00;
  }
  
  .circular-chart.green .circle {
    stroke: #4CC790;
  }
  
  .circular-chart.blue .circle {
    stroke: #3c9ee5;
  }
  
  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }  
 
  /* DealProfile*/
.template-select{
    margin: 0 20px 20px 0;
  }
  
/* Projects Profile*/
.profile-description-rte{
    padding: 5px;
    height: 100%;
    all: initial;
}

.text-line-through {
  text-decoration: line-through;
}

/*.projectsAndTasksTable .ant-table-row-level-0{
    background-color: #f1f1f1db;
}*/
.projectsAndTasksTable .ant-table-row-level-1{
    background-color: #e7eef5b8;
}
.projectsAndTasksTable .ant-table-row-level-2{
    background-color: #f4f5e7b8;
} 

.projectsAndTasksTable .ant-table-row-level-2 td:first-of-type{
    padding-left: 0;
    padding-right: 0;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 250px;
    margin-right: 8px;
    height: 118px;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item-thumbnail{
    width: 180px;
    height: 98px;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item-image{
    width: 208px;
    height: 98px;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item-name{
    padding-left: 98px;
    line-height: 98px;
    display: none;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item-card-actions.picture{
    top: 50px;
}

.projectsAndTasksImagePreview .ant-upload-list-picture{
    background: white;
    margin-top: 15px;
    padding: 10px;
}

.ant-table.ant-table-bordered > 
.ant-table-container > 
.ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > 
.ant-table-container > .ant-table-header > table > thead > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td{
    border-right: 0px !important;
}

.invoice-profile .document-profile-tabs {
    display: -webkit-flex;
    -webkit-justify-content: flex-end;
}

@media only screen and (min-width: 1200px) {
    .email-profile-message{
        height: 100%;
    }

}
.import.tableCollection table{
    background-color: #fff;
}
.import.tableCollection .breadcrumb{
    margin: 10px 0 5px -10px;
}
 .import.tableCollection .breadcrumb a {
    font-size: 18px;
    font-weight: bold;
    color: #4DA1BE;
    text-decoration: none;
}
.import.tableCollection .breadcrumb i {
    font-size: 14px;
    margin-right: 7px;
    font-weight: bold;
    color: #4da1be;
}

.import.tableCollection .import-buttons{
    margin-bottom: 30px;
    height: 50px;
}

.import.tableCollection .import-buttons button{
    margin-left: 0;
}

.import.tableCollection .errors{
    min-height: 200px;
    background-color: white;
    width: 700px;
    padding-left: 30px;
    padding-top: 30px;
    font-size: 17px;
}

.import-data{
    background-color: white;
    margin: 100px;
    width: 60%;
    height: 280px;
    padding: 20px;
}

.import-data.disabled{
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
}
.scan-spinner{
    position: absolute;
    display: flex;
    width: 100%;
    padding: 15px;
    height: 90%;
    background-color: #fbfafa;
    opacity: 0.94;
}

.rounded-button-primary {
    min-width: 56px;
    width: 56px;
    height: 56px;
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 99;
    border-radius: 50%;
    font-size: 25px;
    border: 1px solid #44c699;
    background: #44c699 !important;
    color: #fff !important;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    cursor: pointer;
}
.field img {
    width: 40px;
    border-radius: 100%;
    height: 40px;
}

.input-board {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #f0eae7;
    border: 4px dashed #95d472;
    height: 30%!important;
}

.input-board.beige {
    background-color: beige;
}

.input-board .upload-button {
    margin: 10px 0;
}

.input-board .upload-button i {
    margin: 0;
}
body{
  background: inherit;
}
.tableCollection table {
    width: 100%;
}

.tableCollection {
    display: block;
    position: relative;
}

.tableCollection table > thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.tableCollection table > thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}

.tableCollection table tbody td{
    border: 1px solid #ccc;
}
.tableCollection table tbody td .highlightField{
  background-color: #ff000047;
}
.tableCollection table tbody tr.isDuplicate td.rowNumber{
    background-color: #ff8d00 !important;
    padding: 10px !important;
    text-align: center;
}

.tableCollection table tbody td.rowNumber{
    background-color: #eaf0f5;
    padding: 10px !important;
    text-align: center;
}
.searchLine-quantity{
    min-width: 160px!important;
    line-height: 2.5;
}
.searchLine-quantity input[type='number']{
    min-width: 10px !important;
}

.form-treeview-quantity input[type='number']{
    min-width: 20px!important;
    width: 70px;
}

.tableCollection table td.left, .tableCollection table td.right {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    width: 10px;
    text-align: center;
}

.tableCollection table td.left span i, .tableCollection table td.right span i {
        color: #8c9cb0;
        font-size: 16px;
}
.tableCollection table td.right span i:hover {
     color: red;
}
.tableCollection table td.right {
    border-left: 1px solid #ccc !important;
    padding-left: 10px;
}

.tableCollection table td.left {
    border-right: 1px solid #ccc !important;
    padding-right: 10px;
}

.tableCollection table td.tableHeader {
    background-color: #f0eae7 !important;
}

.tableCollection table td.tableHeader.white {
    background-color: white !important;
}

.tableCollection table td i {
    cursor: pointer;
}

.tableCollection input[type='text'], .tableCollection input[type='number'] {
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
}
.tableCollection input[type='number']  {
    padding: 0 !important;
}

.tableCollection .css-1aya2g8, .tableCollection .css-2o5izw {
    border-radius: 0!important;
}
.tableCollection .css-rsyb7x {
    min-width: 100px;
}
.tableCollection .css-10nd86i, .tableCollection .css-1sontr1 {
    min-width: 189px;
}

.tableCollection .css-1sontr1 .css-1492t68{
    display: none;
}
.input-group-addon {
    background-color: transparent!important;
}
.panel-heading input[type="text"]:focus  {
    border-right: 1px solid #44c699!important;
}
.percent-input {
    border-right: none!important;
}

.custom-table{
    overflow-x: auto;
    overflow-y: hidden;
}
table.mobileTable{
    border-radius: 4px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 1px #aaa;
    margin: 0;
  }
.mobileTable tbody:nth-child(2n+1){
    background: #eaf0f5;
}
.modal-header{
      z-index: 1050!important;
}
.mobileTable tbody{
    background: #f8f9fc;
}
.mobileTable tbody > tr{
    padding: 5px;
}
table.mobileTable .mobileTable-td{
    border: none;
    position: relative;
    white-space: normal;
    text-align: left;
}
.mobileTable-label{
  padding-right: 10px;
   white-space: nowrap;
   text-align: left;
   font-weight: bold;
   width: 100px;
}
.searchBar{
    display: flex;
    align-items: baseline;
}
.searchBar input [type="text"]{
  width: 400px;
  border-radius: 3px;
}

.searchBar .button-primary{
  margin-top: 0px;
}
.table .ant-tree li .ant-tree-node-content-wrapper {
    white-space: normal;
    height: auto;
}
.table .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected, .table .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
    white-space: inherit;
}

.ant-input-number .ant-input-number-input-wrap input{
  text-align: right;
  padding-right: 35px!important;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}

.tableCollection table td.borderNone.left{
    border-right: 1px solid #f0eae7 !important;
}
.tableCollection.tableOverflow{
  overflow-x: auto;
}

/* POS */

.pos-billing-data {
  background-color: white;
  margin-top: 0px;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: 0 1px 1px #aaa;
  margin-bottom: 10px;
  overflow: auto;
}
.pos-customer .panel.total{
    min-height: 100px!important;
    padding-bottom: 10px;
    margin: 0px;
    margin-bottom: 15px;
}
.pos-customer .panel-heading{
    padding: 8px 25px;
}
.pos-customer .col-md-12 {
    padding-right: 10px;
}
.pos-customer .panel.total .form-group label{
  display: none;
}
.pos-billing-data .posTable{
    margin-bottom: 10px;
    width: 100%;
}
.pos-billing-data .posTable thead, .pos-billing-data .posTable tbody tr{
    border-bottom: 1px solid #cccccc;
}
.pos-billing-data .totals tr.line{
   border-top: 1px solid #cccccc;
}
.pos-billing-data .posTable td, .pos-billing-data .totals td{
    width: 100%;
    padding: 3px 5px;
}
.pos-billing-data .totals td{
    width: 80%;
}
 .pos-billing-data .totals td{
   padding-left: 10px;
   padding-right: 10px;
 }
.pos-billing-data .posTable tbody input{
    width: 100px;
    border: 1px solid #d6d6d6;
    font-weight: 600;
    text-decoration: underline;
    font-size: 15px;
    padding: 0px;
    min-width: 100px!important;
    margin-bottom: 0px;
}
.pos-billing-data .posTable thead td{
    min-width: 100px!important;
    text-align: center;
}

.pos-billing-data .posTable input[type='text']{
    width: 100%;
    min-width: 100px!important;
}
.pos-billing-data .posTable input[disabled]{
    background-color: inherit!important;
    text-decoration: none;
    border: none;
}

.pos-billing-data .totals{
    width: 100%;
}
.pos-billing-data .totals .value{
   padding-right: 50px;
}
.pos-billing-data .totals .value h4{
    float: right;
}
.pos-button .button-primary {
    width: 250px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
}
.pos-billing-data .posTable input[type='text'] {
  min-width: 100px!important;
}
.posCategory{
    margin-bottom: 5px;
}
.posCategory .ant-card:hover{
  border-top: 1px solid #44c699;
}
.posCategory-card{
   text-align: center;
   height: 160px;
   margin-right: 5px!important;
   align-items: center;
   display: grid;
}
.posCategory-sku{
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 15px;
}
.posCategory-price{
  font-size: 15px;
}
.posCategory-img{
    margin: 0 auto;
    max-width: 60px;
    margin-top: 10px;
}
.posCategory .ant-card-body{
    padding: 5px;
}
.pos.breadcrumbs{
    align-items: center;
    background-color: #d9e0ef;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 60px;
    height: 40px;
    display: grid;
    color: rgba(0, 0, 0, 0.65);
    padding: 0 10px;
  }
.posPage .search #search{
  padding-left: 30px!important;
}
.pos.breadcrumbs .ant-breadcrumb-link{
    cursor: pointer;
}
.pos.breadcrumbs .ant-breadcrumb-separator{
    margin: 0 5px;
  }
.left-menuPos{
   background-color: #f0eae7;
   position: absolute;
   top: 24px;
   left: 16px;
   z-index: 1000;
}
.list-tableLink {
    cursor: pointer;
    text-decoration: none;
}
.list-tableRow .top {
    font-weight: 600;
}
.list-tableRow .first {
    padding-right: 8px;
    border-right: 1px solid grey;
}
.list-tableRow .second {
    padding-left: 8px;
}
.list-tableRow .list-date, .list-tableRow .list-stage {
    color: #879697;
}
#pos-content .dashboard .moduleSubMenu h3, #pos-content .moduleSubMenu h3 {
    padding-left: 80px;
}
#makePayment .padding10{
  padding: 0px 10px!important;
}

label{
    font-size: 16px;
    color: inherit;
}
.table-inbox{
    font-size: 15px;
}
.heightAuto .css-1tkhqcq{
  height: auto;
}
.col-centered{
    float: none;
    margin: 0 auto;
}
.printDatasource{
    background-color: white;
    margin: auto;
    height: 1123px;
    max-width: 864px;
    margin-bottom: 50px;
    border-radius: 2px;
    box-shadow: 0 0 7px 0 #d4d4d4;
    overflow: auto;
    padding: 40px;
}

.dashboard-spinner{
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  padding: 15px;
  height: 90%;
  background-color: #fbfafa;
  opacity: 0.94;
}

    /* Mobile*/

@media only screen and (max-width: 760px){
  .pos-categories{
    display: none;
  }
  .pos-customer .row.m-bot15 {
      margin: 0px;
  }
  .posPage .col-xs-12{
    padding-right: 15px;
  }
  .panel.total p.total {
    font-size: 17px;
  }
}

@media only screen and (max-width: 780px) {
    .custom-table{
        overflow-x: visible;
        overflow-y: visible;
    }
}

@media only screen and (max-width: 795px){
  .posCategor-card{
    margin-right: 0px;
  }
  .posCategory-img{
    max-width: 40px;
  }
  .pos-billing-data .posTable td{
    padding: 0px;
  }
  .pos-billing-data .posTable thead, .tableCollection table thead, .searchItem-table thead{
      display: none;
  }
  .pos-billing-data .posTable tbody td:before, .tableCollection table tbody td:before, .searchItem-table tbody td:before{
    content: attr(data-title);
  }
  .pos-billing-data .posTable tbody td:before, .tableCollection table tbody td:before, .searchItem-table tbody td:before{
      position: absolute;
      top: 6px;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
  }
    .tableCollection, .searchItem-table {
      width: 100%!important;
  }
  .tableCollection table tbody td:before, .searchItem-table tbody td:before{
      font-weight: bold;
      font-size: 14px;
  }
  .searchBar{
    display: grid;
    margin-bottom: 10px;
  }
  .searchBar .button-primary{
    margin-top: 10px;
  }
  .tableCollection input[type='number'], .searchItem-table input[type='number']{
    width: 70%;
    padding-right: 10px!important;
  }
  .searchBar input [type="text"]{
    width: 100%;
  }
  .searchBar input {
    width: auto;
  }
  .pos-billing-data .posTable tbody tr, .tableCollection table tbody tr, .searchItem-table tbody tr{
      display: block;
      background: white;
  }
  .pos-billing-data .posTable tbody tr:nth-child(2n+1) {
      background: #f8f9fc;
  }
  .tableCollection table tbody tr:nth-child(2n+1), .searchItem-table tbody tr:nth-child(2n+1){
      background: #eaf0f5;
  }
  .pos-billing-data .posTable tbody td, .tableCollection table td, .searchItem-table td{
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%!important;
    white-space: normal;
    text-align: left;
    min-height: 40px;
    display: block;
  }
  .pos-billing-data .totals td {
    width: auto;
  }
  .tableCollection table td, .searchItem-table tbody td{
    border-bottom: none!important;
  }
  .searchItem-table tbody td {
    border: 1px solid #ccc;
    border-bottom: none;
    }
  .tableCollection table tr, .searchItem-table tbody tr{
   border-bottom: 1px solid #ccc;
  }
  .pos-billing-data .totals td{
    padding: 0px 10px;
  }
  .printDatasource{
    padding: 0px;
  }
}

@media only screen and (max-width: 1200px){
  .pos-billing-data .posTable input[type='text']{
    min-width: 100px!important;
  }
  .pos-billing-data .posTable thead td, .pos-billing-data .posTable tbody input{
    min-width: 50px!important;
  }
  .pos-billing-data .posTable input{
      height: 30px;
      width: 100%!important;
      font-size: 13px!important;
      padding: 7px!important;
      text-align: left;
  }
  .posPage .panel-heading span {
    font-size: 13px;    margin-bottom: 10px;
  }
 .slide-modal .modalConfirms{
    width: 95%;
  }
  .add-customer-label>i {
    font-size: 16px;
    margin: 0 3px 0 0;
  }
  .add-customer-label>p{
    font-size: 13px;
  }
  .tableCollection{
    width: 100%!important;
  }
  .searchBar span{
    display: flex;
  }
}

.List {
  border: 1px solid #d9dddd;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;
}

.tableCell {
    min-width: 100px;  
    height: 45px;   
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #7c8484;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

}

.tableSpan{
    height: 100%;
    width: 100%;
    min-height: 45px;
    max-width: 190px;
    font-size: 14px; 
}

.leftLine {
    border-right: 1px solid #ccc !important;
    padding-right: 10px;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    width: 10px !important;
    text-align: center;
}

.rowNumberLine {
    background-color: #eaf0f5;
    text-align: center;
    min-width: 30px !important;    
}

.menu-outer-top {
    bottom: 35px!important;
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    border-top-right-radius: 4px!important;
    border-top-left-radius: 4px!important;
}

.marginBottom {
    margin-bottom: 15px !important;
}

.marginBottom input[type='text'] {
    margin-bottom: 15px !important;
}


.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}
[data-theme="dark"] .site-form-in-drawer-wrapper {
  border-top: 1px solid #303030;
  background: #1f1f1f;
}

.tableCollection table tbody td{
    border: 1px solid #ccc;  
    max-height: 45px;
}

.tableCollection table tbody tr.isDuplicate td.rowNumberLine{
    background-color: #ff8d00 !important;
    padding: 10px !important;
    text-align: center;
}

.tableCollection table tbody td.rowNumberLine{
    background-color: #eaf0f5;
    padding: 10px !important;
    text-align: center;
}

.Toastify__toast-container {    
    margin: 0 auto;
    text-align: center;
    height: 50px;
    font-size: 18px;
    line-height: 2;
    color: white;
    border-radius: 10px 10px 0px 0px;
}

.Toastify__toast {  
    position: relative;
    min-height: 84px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
     color: white;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
}
.Toastify__toast Toastify__toast--error{
     border-radius: 10px 10px 0px 0px;
}
.grow-font-size {
     border-radius: 10px 10px 0px 0px;
}

.black-background {
   border-radius: 10px 10px 0px 0px;
  }

.Toastify__toast-container--bottom-center {
   border-radius: 10px 10px 0px 0px;
  }
  
.Toastify__toast--success {
  border-radius: 10px 10px 0px 0px !important;
  background: #44c699 !important;
}  
.Toastify__toast--warning {
  border-radius: 10px 10px 0px 0px !important;
}
.Toastify__toast--error {
  border-radius: 10px 10px 0px 0px !important;
   background: #e50000 !important;
}
.toastBody {
  border-radius: 10px 10px 0px 0px !important;
}
.toastProgress {
  border-radius: 10px 10px 0px 0px !important;
}

.sticky-grid__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    z-index: 3;
}

.table-responsive 
{   
    width: 100%;
    margin-bottom: 15px;
    overflow-x: auto;   
    overflow-y: hidden;     
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #000000; 
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 20;
}

.rowT,
.sticky {
  display: flex;
  align-items: center;
  background-color: #eaf0f5 !important;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}


user agent stylesheet
input:-internal-autofill-selected {
    -webkit-appearance: menulist-button;
            appearance: menulist-button;
    background-color: white !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
}

input.ant-input.ant-input-sm{
  background-color: white !important;
}
.sticky-grid__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    z-index: 3;
}

.sticky-grid__header__base {
    z-index: 3;
    background: #eaf0f5;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    border: 1px solid #ccc;
    font-weight: bold;
}

.sticky-grid__header__scrollable {
    position: absolute;
}

.sticky-grid__header__scrollable__column {
    position: absolute;
    background-color: #eaf0f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    border: 1px solid #ccc;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    font-weight: bold;
}

.sticky-grid__sticky-columns__container {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: #eaf0f5;
    width: -webkit-min-content;
    width: min-content;
}

.sticky-grid__sticky-columns__row {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ccc !important;
    z-index: 10;
    /*padding-left: 10px;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;*/
}

.sticky-grid__data__container {
    position: absolute;
}

.sticky-grid__data__column {
    /*  display: flex; */
    flex-direction: row;
    align-items: left;
    border: 1px solid #ccc;
    /* padding-left: 10px;
     border-right: 1px solid gray;
     border-bottom: 1px solid gray;*/
}

.border-green {
    border: 1px solid greenyellow !important;
}

div + div.border-green {
    border: 1px solid greenyellow !important;
}

.sticky-grid__container {
    background: white;   
}

.div-text-center {
    text-align: center;

}

.div-text-right {
    text-align: right !important;
}

.highlightField {
    background-color: #ff000047 !important;
}



.dataTable input[type="text"],
.dataTable input[type="number"],
.dataTable .css-1aya2g8{
	margin-bottom: 0;
	border-radius: 0;
	border: none;
}

.dataTable td{
	border: 1px solid #ccc;
}

.dataTable thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.dataTable thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 24-Apr-2019, 10:11:58
    Author     : neva
*/

input.custom-text {
    background: white;
    height: 100px;
    line-height: 25px;
    margin-top: 10px;
    box-shadow: 0 1px 1px #aaa;
    margin: 30px 0 0 0;
    border-color: #d3d3d3;
}

button[disabled].button-issue {
    background-color: rgba(0,255,0,0.3)!important;
    border: 1px solid rgba(0,255,0,0.3);
}

.form-contacts {
    min-width: unset;
    width: 40%;
    height: 40px;
    margin-right: 10px;
    color: black;
}

.form-stage{
    min-width: unset;
    width: 100%;
    height: 40px;
    color: black;
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
    border-radius: 4px;
}
.stages table tbody td{
    padding: 0px 10px;
}
.form-stage.form-stage-select{
    min-width: 120px;
    border: 1px solid #dbdadb!important;
  }
.contact-icon {
    float: right;
    margin-top: -27px;
    margin-right: -10px;
    cursor: pointer;
}

.add-translations {
    position: absolute;
    left: 48%;
    top: 140px;
}

 i.fa.fa-language {
    cursor: pointer;
}

.sub-document {
     background: #F4F4F4;
}

.main-document {
    font-weight: 600;
}

.panel .panel-body .mobileSelect{
    padding-left: 0px;
}

.set-in-place {
    position: absolute;
    top: 9px;
    left: 100px;
}

.bom-set-in-place {
    position: absolute;
    top: 3px;
    right: 100px;
}

.set-in-place-make-payment {
    position: relative;
    top: 30px;
}
.pay_total .form-group{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}
.pay_total .panel.total {
    border: none;
    box-shadow: none;
    min-height: auto;
  }
.disabled-breadcrumb {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.simple-form-field {
    font-size: 20px;
}

.ant-pagination {
    text-align: center;
    margin-bottom: 10px;
}

.form-input-field .ant-select-selection--single {
    height: 40px;
    border: none;
    border-radius: 0;
}

.form-input-field .ant-select-enabled {
    width: 100%;
}

.help {
    position: relative;
    top: -7px;
    left: 3px;
}
.ant-calendar-picker-input.ant-input {
    height: 40px;
    margin-bottom: 15px;
}

.multipleSelectList .css-xwjg1b {
    display: none;
}

.collapsible {
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: 1px solid lightgrey;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-radius: 4px;
}

.active, .collapsible:hover {
  background-color: #f1f1f1;
}

.ant-switch-checked{
    background-color: #38b88c!important;
}

.searchItem-table tbody td input[type="number"]{
    min-width: 100px!important;
}

/*.content {
    padding: 35px;
    overflow: hidden;
    background-color: white;
    border: 1px solid lightgrey;
}
*/

.ant-card-extra {
    cursor: pointer;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    /* height: 40px; */
    border-color: #40a9ff;
    border-right-width: 1px !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
}

.ant-form > .ant-select-selector {
    height: 40px!important;
}

.account-profile-file-upload .ant-select-selector {
    height: 42px!important;
  }

select.form-control {
    border: 1px solid #d6d6d6;
    height: 40px;
    padding: 0 5px;
    float: left;
    color: #444;
    font-weight: 300;
    font-size: 16px;
    border-radius: 3px;
    background: transparent;
    min-width: 120px; 
    padding: 10px 0;
    margin-bottom: 15px;
    letter-spacing: .5px;
    width: 100%;
}

.newPanel .panel-body .row #reactForm {
background: white;
}

.m-right {
    margin-left: 30px;
}

.h-field {
    height: 40px !important;
    margin-bottom: 50px;
    max-height: 40px;
}


/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 19.01.2021, 09:12:58

.Toastify__toast-container--bottom-center {
    background: #44c699;
    margin: 0 auto;
    text-align: center;
    height: 40px;
    font-size: 18px;
    line-height: 2;
    color: white;
    border-radius: 10px 10px 0px 0px;
}   Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/
.Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, 9999px);
    position: fixed;
    padding: 4px;
    box-sizing: border-box;
    color: #fff;
     bottom: 0px;
    left: 10%;
    right: 10%;
    width: 80% !important;
    float: center;
    text-align: center;
}
  .dms_new_menu{
    padding: 20px 15px 0;
    display: table;
    min-height: 68px;
    width: 100%;
    background: #fff;
    border-bottom: 2px solid #e8e7ea;
    margin-bottom: 0;
}
.dms_new_menu .navigation{
   display: flex;
   align-items: center;
}
.dms_new_menu h3.module-title {
    margin: 0px;
}
  .dms_new_menu .button-primary{
    margin: 15px;
  }
  .dms-upload{
    display: flex;
  }
  .dir_row{
    margin: 10px 20px;
  }
  .dir{
    padding: 5px;
  }
  .dir_folder{
    background: #e0e0f5;
    border: 1px solid #d6d6e0;
    width: 100%;
  }
  .dir_element{
    max-height: 130px;
    font-size: 100px;
    color: white;
    text-align: center;
  }
  .dir_element:hover{
    color: white;
  }
  .dir_icon:focus{
    outline: none;
  }
  .dir_link{
    padding: 10px 0 10px 0;
    margin: 0 15px;
    text-decoration: none;
  }
  .dir_link:hover{
    border-bottom: 2px solid #f31c3f;
    text-decoration: none;
  }
  .dir_card{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    transition: all 0.3s;
    border: 1px solid #e8e8e8;
    padding: 6px;
  }
 .dir_image{
   position: relative;
   left: 0;
   right: 0;
   z-index: 1;
   display: block;
   background-position: left;
   background-repeat: no-repeat;
   background-size: cover;
   height: 200px;
   -moz-filter: blur(9px);
   -o-filter: blur(9px);
   -ms-filter: blur(9px);
   filter: blur(9px);
  }
  .dir_image2{
    position: absolute;
    left: 0;
    right: 0;
    top: 5px;
    z-index: 99;
  }
  .dir_image2 img{
   max-width: 100%;
   max-height: 200px;
   display: block;
   margin: auto;
   width: auto;
  }
  @media only screen and (max-width: 767px){

  }
 /* .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 30%;
    right: 30%;
    margin-left: -160px;
    width: 80% !important;
    font-size: 16px;
    background: #44c699;
}
.Toastify__toast--default {
    padding: 30px;
}*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* page styling */
.pg-content {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Fira Sans', Arial, Helvetica, sans-serif;
  background-color: #f2f6ff;
}

/* for print */
.invoice-title {
  width: 48%;
  padding: 0;
  text-align: center;
}

.printData {
  background-color: white;
  margin: auto;
  border-radius: 2px;
  padding: 40px;
}

/* Header */
.navbar.pg-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 50;
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.bar-colors {
  position: absolute;
  bottom: -3px;
  width: 100%;
  height: 3px;
  background-image: linear-gradient(to right, #fc5c7d, #6a82fb);
}
.logo-wrapper {
  display: inline-block;
  margin: 5px;
}
.logo-wrapper img {
  display: inline-block;
  width: auto;
  height: 100%;
}
.navbar-right {
  display: flex;
  align-items: center;
  margin-right: 125px;
  margin-top: 12px;
}
.navbar-right .icon-wrapper {
  display: flex;
  margin-right: 30px;
}
.navbar-right .language {
  display: flex;
  align-items: center;
}
.navbar-right .language span {
  color: black;
}
.navbar-right .language:hover {
  text-decoration: none;
}
.navbar-right .language .icon-wrapper {
  display: flex;
  margin-right: 5px;
}

.container.pg-container {
  padding: 80px 0 30px;
  width: 100%;
  max-width: 1480px;
  min-height: 100vh;
  margin: 0 auto;
}

/* Breadcrumbs */
.breadcrumb.pg-breadcrumbs {
  width: 100%;
  margin: 0 0 15px;
  padding: 0;
  background-color: transparent;
  /* is in use */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.pg-breadcrumbs > li {
  font-size: 16px;
}
.pg-breadcrumbs .anticon-right {
  margin: 0 5px;
  color: #777;
  font-size: 12px;
}
.pg-breadcrumbs .anticon-right svg {
  width: 10px;
  height: 10px;
}
.pg-breadcrumbs .breadcrumb-item a {
  color: #212529;
}
.pg-breadcrumbs .breadcrumb-item.active {
  color: black;
}

/* Card */
.card.pg-card {
  position: relative;
  z-index: 2;
  margin: 0 0 20px;
  padding: 30px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.card .card-price {
  margin: 0 0 15px;
  padding: 10px;
  border-radius: 6px;
  /*background-color: #f5f5f5;*/
  background-color: #f2f6ff;
}
.card .lead {
  font-size: 16px;
  font-weight: 600;
}
.card .card-price .total {
  display: flex;
  align-items: baseline;
  grid-gap: 6px;
  gap: 6px;
  font-size: 24px;
}
.card .card-price .total .curency,
.card .card-price .total .curency-sign {
  font-size: 18px;
}

.card .card-price--info {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  background: #fff;
}
.card .card-price--info .lead {
  margin-bottom: 0;
}
.card .card-price--info .status,
.card .card-price--info .due-date {
  width: 50%;
}
.card .payment-history {
  display: flex;
}
.card .payment-history .date {
  width: 65%;
  font-size: 16px;
  font-weight: bold;
}
.card .payment-history .payment-info .curency {
  font-size: 12px;
}

/* Buttons */
.buttons {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 40px;
  z-index: 20;
}
.btn-download {
  padding: 10px 30px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.04em;
  border-radius: 10px;
  background-color: #5bba6f;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 300ms ease-in-out;
}
.btn-download:hover,
.btn-download:focus,
.btn-download:active {
  background-color: #3fa34d;
  color: #fff;
  transform: translateY(2px);
}
.btn-pay {
  padding: 10px 30px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.04em;
  border-radius: 30px;
  background-color: #5bba6f;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 300ms ease-in-out;
}
.btn-pay:hover,
.btn-pay:focus,
.btn-pay:active {
  background-color: #3fa34d;
  color: #fff;
  transform: translateY(2px);
}

/* issuer and decorations */
.software {
  position: relative;
  z-index: 20;
  display: inline-block;
  width: 100%;
  margin: 50px auto 0;
  font-size: 16px;
  text-align: center;
}
.software span {
  display: inline-block;
  padding: 5px;
  background-color: #f2f6ff;
}
.bg-icon-wrapper-left,
.bg-icon-wrapper-right {
  position: absolute;
  z-index: 0;
}
.bg-icon-wrapper-left > img,
.bg-icon-wrapper-right > img {
  display: inline-block;
  width: 100%;
  height: auto;
}
.bg-icon-wrapper-left {
  width: 300px;
  bottom: 0;
  left: 0;
}
.bg-icon-wrapper-right.fix {
  top: 80px;
  right: 0;
}

#printDoc.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #323639 !important;
}
.mobile-text {
  display: none;
  max-width: 100%;
  padding: 30px 0 40px;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
}

/* Responsive adjust */
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

@media (max-width: 769px) {
  .container.pg-container {
    padding-top: 70px;
  }
  .breadcrumb {
    font-size: 14px;
  }
  .mobile-embed {
    display: none;
    max-width: 100%;
    padding: 0px 20px;
  }
  .mobile-text {
    display: block;
  }
  .card.pg-card {
    width: 100%;
    padding: 20px 15px;
  }
  .mobile-skeleton {
    display: none;
  }
  .card-price--info {
    padding-left: 10px;
  }
  .buttons {
    justify-content: center;
  }
  .software {
    width: 100%;
    margin-top: 30px;
  }
  .bg-icon-wrapper-left {
    width: 200px;
  }
  .bg-icon-wrapper-right.fix {
    top: 240px;
  }
}
@media (max-width: 992px) {
  .mobile-embed {
    margin-bottom: 20px;
  }
}

/* OLD Styles not in use */
/*
.invoice-title {
  width: 48%;
  padding: 0;
  text-align: center;
}

.printData {
  background-color: white;
  margin: auto;
  border-radius: 2px;
  padding: 40px;
}
.navbar {
  background-color: white;
  position: relative;
}
.bar-colors {
  position: absolute;
  bottom: -3px;
  width: 100%;
  height: 3px;
  background-image: linear-gradient(to right, #FC5C7D, #6A82FB);
}
.logo-wrapper img {
  display: inline-block;
  max-height: 100%;
  margin: 5px;
}
.navbar-right {
  display: flex;
  align-items: center;
  margin-right: 125px;
  margin-top: 12px;
}
.navbar-right .icon-wrapper {
  display: flex;
  margin-right: 30px;
}
.navbar-right .language {
  display: flex;
  align-items: center;
}
.navbar-right .language span {
  color: black;
}
.navbar-right .language:hover {
  text-decoration: none;
}
.navbar-right .language .icon-wrapper {
  display: flex;
  margin-right: 5px;
}
.container {
  width: 1420px;
  max-width: 1480px;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 0;
  min-height: calc(100vh - 50px);
  font-family: 'Fira Sans', sans-serif;
}
.container .breadcrumb {
  background-color: transparent;
  padding-left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100vw;
}
.container .breadcrumb-item a {
  color: #212529;
  font-size: 16px;
}
.container .breadcrumb-item.active {
  color: black;
}

.breadcrumb > li + li:before {
  padding: 0 3px;
}
.container .breadcrumb > li {
  font-size: 16px;
}

.card {
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}
.card-body {
  padding: 30px;
  background-color: white;
  border-radius: 24px;
}
.card .card-price {
  /*background-color: #f5f5f5;*/ /*
  background-color: rgb(242, 246, 255);
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 15px;
}

.card .lead {
  font-size: 16px;
  font-weight: bold;
}
.card .card-price .total {
  font-size: 22px;
  display: flex;
  align-items: baseline;
}
.card .card-price .total .curency {
  font-size: 17px;
  margin-left: 5px;
}

.card .card-price .total .curency-sign {
  margin-left: 5px;
}

.card .card-price--info {
  background-color: white;
  display: flex;
}
.card .card-price--info .lead {
  margin-bottom: 0;
}
.card .card-price--info .status,
.card .card-price--info .due-date {
  width: 50%;
}
.card .payment-history {
  display: flex;
}
.card .payment-history .date {
  width: 65%;
  font-size: 16px;
  font-weight: bold;
}
.card .payment-history .payment-info .curency {
  font-size: 12px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.btn-download {
  padding: 5px 30px;
  font-size: 24px;
  background-color: #5bba6f;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 40%; */ /*
  font-weight: bold;
}
.btn-download:hover {
  color: #fff;
  background-color: #3fa34d;
}
.btn-pay:hover {
  background-color: white;
  color: #44c699;
}
.btn-pay {
  padding: 5px 67px;
  font-size: 24px;
  color: white;
  background-color: #44c699;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 40%; */ /*
  font-weight: bold;
}
.software {
  margin-top: 55px;
  font-size: 16px;
  text-align: center;
}
.bg-icon-wrapper-left {
  position: absolute;
  bottom: 0;
  left: -300px;
  width: 300px;
}
.bg-icon-wrapper-left img {
  width: 100%;
  height: auto;
}
.bg-icon-wrapper-right.fix {
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 0;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

#printDoc.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #323639 !important;
}

.mobile-text {
  max-width: 100vw;
  display: none;
  padding: 20px 30px 20px 20px;
  text-align: center;
  font-size: 20px;
}
@media (max-width: 768px) {
  .container {
    padding-top:20px;
  }
  .mobile-embed {
    padding: 0px 20px;
    max-width: 100vw;
    display: none;
  }

  .breadcrumb {
    font-size:14px;
  }

  .mobile-text {
    display: block;
  }
  .card-body {
    padding: 30px 15px;
  }
  .card {
    width: 96%;
  }
  .mobile-container {
    padding: 0px 20px;
    max-width: 100vw;
    position: relative;
    z-index: 10;
  }
  .justify-content-center {
    width: 100vw;
    z-index: 10;
    position: absolute;
    bottom: 50px;
  }

  .mobile-skeleton {
    display: none;
  }
  .card-price--info {
    padding-left: 10px;
  }
  .bg-icon-wrapper-right.fix {
    top: 260px;
  }
}
*/

.item-requestPage .ant-calendar-picker-input.ant-input{
    border: none;
    border-radius: 0px;
    margin-bottom: 0px;
}
.item-requestPage .ant-calendar-range-picker-input{
  cursor: pointer;
}
.listGrid{
  box-shadow: inset 4px 1px 7px 0 rgba(0, 0, 0, 0.06);
  background-color: #fbfaff;
  z-index: 999;
}
.list-header{
  display: flex;
  padding-bottom: 10px;
  margin: 0 15px;
  align-items: center;
}
.list-header .ant-select-selection__rendered, .listSingle-body .ant-select-selection__rendered{
  line-height: 28px!important;
}
.list-headerTitle{
  font-size: 18px;
  margin-right: 10px;
}
.list-header .filterBox{
   background: white;
   border-radius: 4px;
   box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
   border: solid 1px #e5e0ea;
   padding: 15px 20px;
   text-align: center;
   text-transform: uppercase;
   font-size: 16px;
   cursor: pointer;
   text-decoration: none;
}
.list-tableRow .top{
  font-weight: 600;
  min-width: 330px;
}
.list-tableRow .first{
  padding-right: 8px;
  border-right: 1px solid grey;
}
.list-tableRow .second{
  padding-left: 8px;
}
tr.list-tableRow.focused {
    background: #e6f7ff;
    box-shadow: -3px 3px 4px 0 rgba(0, 0, 0, 0.05);
}
.list-tableRow .list-date, .list-tableRow .list-stage{
  color: #879697;
}
.list-tableLink{
  cursor: pointer;
  text-decoration: none;
}
.list-tableLink:hover{
  text-decoration: none;
}
.listFilters-select .ant-select-selection--single {
    height: 41px;
  }
.listSingle-info {
  background: white;
}
.listSingle-header{
  margin: 15px 0px 25px;
}
.listSingle-shodow{
  box-shadow: inset 4px -1px 7px 0 rgba(0, 0, 0, 0.06);
  background-color: #fbfaff;
}
.listSingle-body{
  margin-top: 20px;
}
.listSingle-info .actions{
  margin-top: 10px;
}
.listSingle-info .link-button{
  margin-left: 6px;
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 15px;
  font-weight: 600;
}
.listSingle-info .link-button a{
  color: #4eb0e2;
}
 .listSingle-table{
   margin-bottom: 50px;
 }
.listSingle-item{
     border: 1px solid #dae1e4;
     border-radius: 7px;
     background-color: #fff;
     margin: 15px 0px;
     padding: 15px 18px 9px;
     width: 100%;
}
.listSingle-item .qty{
    text-align: right;
    color: #222;
    font-size: 18px;
    font-weight: 700;
}
.listSingle-info .stage {
    padding-left: 10px!important;
}
.listSingle-info .stage.greenBorder {
    border-left: 2px solid #44c699;
}
.listSingle-info .stage.orange {
    border-left: 2px solid #ffa500;
}
.request-tabs-tab{
    height: 40px;
    margin: 0;
    margin-right: 2px;
    padding: 0 16px;
    line-height: 38px;
    background: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.request-tabs-tab.active{
  height: 40px;
    color: #1890ff;
    background: #fff;
    border-color: #e8e8e8;
    border-bottom: 1px solid #fff;
    font-weight: 600;
  }
.request-tabs-tab.active:before{
    border-bottom: 2px solid transparent;
  }

.rdtPicker {
    display: block;
    position: static;
}

.pivoting .table-inbox{
    position: relative;
    overflow: auto;
    margin: 0 15px;
    background: #f8f9fc;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    font-size: 13px;
    width: 100%;
    height: 100%;
}

.load{
    padding: 5px;
}
.loading{
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: 15px;

}
.sectionBackground {
    background: #f0eae7;
    overflow-x: hidden;
    height: -webkit-fill-available;
}

.pvtDropdownValue {
    padding: 2px 5px;
    font-size: 12px;
    text-align: left;
}

.pvtDropdownCurrentOpen {
    border-radius: 4px 4px 0 0;
}

.pvtDropdownCurrent {
    text-align: left;
    border: 1px solid #a2b1c6;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    width: 210px;
    box-sizing: border-box;
    background: #fff;
}
.pvtUi {
    color: #2a3f5f;
    font-family: Verdana;
    border-collapse: collapse;
}

.pvtUi {
    width: 100%;
}

.pvtDropdownMenu {
    background: #fff;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    border: 1px solid #a2b1c6;
    border-top: 1px solid #dfe8f3;
    box-sizing: border-box;
}

.pvtSearch {
    min-width: 270px!important;
}

.pvtUi { width: 100%; }
.pvtVertList { width: 15%; }

#pivot .date-range .ant-calendar-picker{
    width: 250px!important;
    min-width: 0px!important;
    background-color: #fff;
}
/*
    Created on : Sep 15, 2020, 2:59:06 PM
    Author     : vk
*/
/*body{
        background-color: #fbfaff!important;
}*/

.login{
  font-family: 'ProximaNovaA',sans-serif!important;
  background-color: #fbfaff!important;
}
.login-top{
    padding: 30px 0;
    display: flex;
    align-items: baseline;
}
.login-hero{
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  padding-left: 70%;
}
.login-link{
  text-align: center;
  color: #665e5e;
  font-weight: 600;
  margin-right: 20px;
}
.login-button-white{
  width: 154px;
  border-radius: 17px;
  box-shadow: 0 2px 7px 0 #ded9fa;
  background-color: white;
  text-align: center;
  color: #665e5e;
  padding: 10px;
  font-weight: 600;
}
.login-mainRow{
    padding: 7% 0;
}
.login-body{
  background: #fbfaff;
}
.login-title{
  font-size: 37px;
  font-weight: bold;
  line-height: 1.27;
  letter-spacing: 0.1px;
  color: #333333;
  max-width: 560px;
  margin-bottom: 40px;
}
.login-title-white{
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing:0.1px;
  text-align: center;
  color: white;
}
.login-subTitle{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #adb3b5;
}
.auth-button{
  width: 156px;
  border-radius: 23.5px;
  background-color: #ff3465;
  padding: 15px 25px;
  float: right;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
}
.auth-button:hover{
  box-shadow: 0 2px 7px 0 #ded9fa;
}
.buttons-section{
  margin: 30px 0px;
}
.demo-button{
  width: 156px;
  padding: 7px 20px;
  border-radius: 23.5px;
  background-color: #ff3465;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
}
.demo-button:hover, .demo-button:active, .demo-button:focus{
  color: white;
}
/* Form */
.login-input input{
  height: 60px;
  border-radius: 4px;
  border: solid 1px #afafaf;
  background-color: white;
}
.login-input label{
  font-weight: 600;
 }

 .create-button{
  width: 156px;
  border-radius: 23.5px;
  background-color: #ff3465;
  padding: 15px 25px;
  float: right;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  padding: 1px;
  height: 40px;
}

/* MOBILE */
@media only screen and (max-width: 900px) {
  .register{position: absolute;top: 0;left: 0;right: 0;height: auto;}
  .login .bg{display: none;}
  .login-top{display: block;}
  .login-link{margin-left: 20px;}
  .login-mainRow{padding: 0px; margin: 40px 15px;}
  .demo-col{display: none;}
  .login-title{text-align: center;width: 100%;font-size: 30px;}
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 11.01.2021, 16:32:55
    Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/


.button-primary {
    border: 1px solid #44c699;
    background: #44c699 !important;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;
}

.ant-input.is-invalid {    
    webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 60px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e50000;
    border-radius: 4px;
    transition: all 0.3s;
}

.invalid-feedback {
    color: #e50000;    
}

/*
    Created on : Nov 28, 2019, 2:25:14 PM
    Author     : veronika
*/
body{
  font-family: 'ProximaNovaA',sans-serif;
  font-weight: normal;
  color: #32323A;
}
a{
  font-family: 'ProximaNovaA',sans-serif;
  font-weight: normal;
  color: #32323A;
}
#main-content{
    width:calc(100% - 190px);
    float:right;
    background: #f0eae7;
    font-family: 'ProximaNovaA',sans-serif!important;
}
.grid-panel a, .grid-panel a:hover, .moduleSubMenu a, .moduleSubMenu a:hover{
    color: #222227;
}
/*CUSTOM MODAL*/
.customModal{
    display: block;
    background-color: rgba(0, 0, 0, 0.45);
    font-family: 'ProximaNovaA',sans-serif;
}
.customModal-title{
    margin: 0;
    color: black;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}
.customModal-body{
    z-index: 10001;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    pointer-events: auto;
}

.ql-container {
    height: 400px;
}
.header .companyLogo ul:first-child {
  justify-content: flex-start; }
.header .companyLogo ul li {
  padding-left: 0 !important; }
.header .ant-menu-horizontal .ant-menu-item i,
.header .ant-menu-horizontal .ant-menu-item a,
.header .ant-menu-horizontal .ant-menu-item p {
  color: #9c96c4 !important; }
.header .ant-menu-horizontal .ant-menu-item i {
  color: #FFF !important; }
.header .ant-menu-submenu-horizontal i,
.header .ant-menu-submenu-horizontal a,
.header .ant-menu-submenu-horizontal p {
  color: #9c96c4 !important; }
.header .ant-menu-submenu-horizontal i {
  color: #FFF !important; }
.header .ant-menu-submenu-horizontal .anticon-caret-down svg path {
  fill: #9c96c4 !important; }
.header .ant-menu-submenu-open:after {
  content: none !important; }

.ant-menu-submenu-placement-bottomLeft .ant-menu-sub {
  position: relative;
  overflow: inherit !important; }

.ant-menu-submenu-placement-bottomLeft .ant-menu-sub:before {
  content: "";
  position: absolute;
  z-index: 99999999999;
  top: -7px;
  left: 10px;
  width: 0;
  height: 0;
  border-color: transparent transparent #FFF;
  border-style: solid;
  border-width: 0 10px 10px; }

.header.fixed-top.collapsed {
  left: 0 !important;
  width: 100% !important;
  width: calc(100% - 0) !important; }

.submenu-header-ui a:hover {
  color: #5c5c5c !important; }
.submenu-header-ui .ant-menu-item-group-list li {
  height: 32px !important;
  line-height: 32px !important; }
.submenu-header-ui .ant-menu-item-group-title {
  color: rgba(0, 0, 0, 0.3); }

.sidebar {
  height: 100%; }

.submenu-ui.ant-menu-submenu-placement-rightTop {
  top: 49px !important; }

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 calc(29% - 16px / 2) !important; }

.submenu-ui .ant-menu-vertical.ant-menu-sub {
  position: fixed;
  max-height: 93vh !important;
  min-width: 250px !important;
  margin-left: -3px;
  box-shadow: none;
  border-right: 1px solid #CCC !important;
/*  display: flex;
  flex-direction: column;*/ }
  .submenu-ui .ant-menu-vertical.ant-menu-sub li > div {
    margin: 0 !important; }
  .submenu-ui .ant-menu-vertical.ant-menu-sub li ~ li {
    border-top: 1px solid #eeebf1 !important;
    margin: 0 !important; }
.submenu-ui ul li ul li {
  margin: 0 !important; }

.sidebar-menu .ant-menu .ant-menu-title-content img,
.submenu-ui .ant-menu .ant-menu-title-content img {
  width: 150px;
  height: auto; }
.sidebar-menu .ant-menu .tags,
.submenu-ui .ant-menu .tags {
  margin: 0 10px; }
  .sidebar-menu .ant-menu .tags a,
  .submenu-ui .ant-menu .tags a {
    background: #eeebf1;
    border-radius: 50px;
    padding: 10px;
    flex: none;
    margin-bottom: 7px; }
    .sidebar-menu .ant-menu .tags a:hover,
    .submenu-ui .ant-menu .tags a:hover {
      color: #5c5c5c; }

  .purple-menu li {
    margin: 0;
    position: relative;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-size: 15px;
    color: #444; }
    .purple-menu li i {
      color: #858287;
      margin-right: 5px; }
    .purple-menu li:hover span, .purple-menu li:hover svg path, .purple-menu li:hover i {
      color: #5c5c5c !important;
      fill: #5c5c5c; }
    .purple-menu li:active span, .purple-menu li:active svg path, .purple-menu li:active i {
      color: #5c5c5c !important;
      fill: #5c5c5c; }
    .purple-menu li:hover .ant-menu-title-content {
      color: #5c5c5c !important; }
    .purple-menu li:hover > div {
      color: #5c5c5c !important;
      background: #eeebf1 !important; }
    .purple-menu li .ant-menu-submenu-title {
      margin-top: 0;
      margin-bottom: 0; }
  .purple-menu .ant-menu-item:hover {
    background: #eeebf1 !important; }
  .purple-menu li ~ li {
    border-top: 1px solid #eeebf1 !important; }

.submenu-level ~ .submenu-level {
  border-top: 1px solid #eeebf1 !important; }

.submenu-level {
  position: relative;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 40px;
  overflow: hidden;
  color: #858287; }
  .submenu-level .ant-menu-submenu-title {
    height: 32px !important;
    line-height: 32px !important; }
  .submenu-level i {
    color: #858287;
    margin-right: 10px; }
  .submenu-level:hover span, .submenu-level:hover svg path, .submenu-level:hover i {
    color: #5c5c5c !important;
    fill: #5c5c5c; }
  .submenu-level:active span, .submenu-level:active svg path, .submenu-level:active i {
    color: #5c5c5c !important;
    fill: #5c5c5c; }
  .submenu-level:hover .ant-menu-title-content {
    color: #5c5c5c !important; }
  .submenu-level .ant-menu-item-active span {
    color: #5c5c5c !important; }

.has-submenu.ant-menu-submenu-selected,
.submenu-level.ant-menu-submenu-selected {
  color: #5c5c5c !important; }

.submenu-level .ant-menu-item-selected span {
  color: #5c5c5c !important; }

.submenu-level-group ul li ~ li {
  border-top: 1px solid #eeebf1 !important; }
.submenu-level-group ul li {
  margin: 0;
  color: #858287; }
  .submenu-level-group ul li i {
    color: #858287;
    margin-right: 10px; }
  .submenu-level-group ul li:hover span, .submenu-level-group ul li:hover svg path, .submenu-level-group ul li:hover i {
    color: #5c5c5c !important;
    fill: #5c5c5c; }
  .submenu-level-group ul li:active span, .submenu-level-group ul li:active svg path, .submenu-level-group ul li:active i {
    color: #5c5c5c !important;
    fill: #5c5c5c; }

.purple-menu li:last-child {
  width: 100%; }
.purple-menu li:nth-last-child(2) {
  flex-grow: 1; }

.has-submenu.ant-menu-submenu-active.ant-menu-submenu-active .ant-menu-title-content,
.submenu-ui.ant-menu-submenu-active .ant-menu-title-content {
  color: #5c5c5c !important; }

.purple-menu .ant-menu-submenu-active > div,
.submenu-ui .ant-menu-submenu-active > div {
  background-color: #eeebf1 !important;
  color: #5c5c5c !important; }

.submenu-ui.ant-menu-submenu-open .ant-menu-item .ant-menu-item-icon + span,
.submenu-ui.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-item-icon + span,
.submenu-ui.ant-menu-submenu-open .ant-menu-item .anticon + span,
.submenu-ui.ant-menu-submenu-open .ant-menu-submenu-title .anticon + span {
  color: #5c5c5c !important; }

.submenu-ui li span,
.submenu-ui li i {
  font-size: 15px;
  color: #444; 
}

.submenu-ui .ant-menu-submenu-open .ant-menu-item-icon svg path {
  fill: #5c5c5c !important; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f7f1fd !important; }

.submenu-ui ul {
  background: #FFF !important;
  height: 100%; }
  .submenu-ui ul .ant-menu-item-group-title {
    padding: 0; }
  .submenu-ui ul li {
    margin: 0;
    overflow: hidden;
    height: 40px;
    line-height: 40px;
    margin-bottom: 0 !important;
    margin-top: 0; }
    .submenu-ui ul li .ant-menu-submenu-title {
      height: 34px;
      line-height: 34px; }
    .submenu-ui ul li:hover, .submenu-ui ul li:focus, .submenu-ui ul li:active {
      background: #eeebf1 !important; }
  .submenu-ui ul li ~ li {
    border-top: 1px solid #eeebf1 !important; }
  .submenu-ui ul li > div:hover, .submenu-ui ul li > div:focus, .submenu-ui ul li > div:active {
    background: #eeebf1 !important; }
.submenu-ui .ant-menu-item-group div:hover {
  background: #FFF !important;
  background-color: #FFF !important; }
.submenu-ui .ant-menu-item-group-list li ~ li {
  border-top: 1px solid #eeebf1 !important; }
.submenu-ui .ant-menu-submenu-title {
  height: 34px;
  line-height: 34px; }

.ant-menu-item-group-title {
  padding: 8px 16px 0 16px !important; }
  .ant-menu-item-group-title:hover {
    background: transparent !important;
    background-color: transparent !important; }

.ant-menu-title-content {
  margin-left: 0 !important; }

.submenu-ui .ant-menu-item:active,
.submenu-ui .ant-menu-submenu-title:active {
  background: #f7f1fd !important; }

.purple-menu {
  z-index: 5;
  background: #f7f1fd !important; }
  .purple-menu ul {
    background: #f7f1fd !important; }

.sidebar-wrapper {
  height: 100vh;
  background: #f7f1fd !important; }

.sidebar-menu .navbar-logo-collapse .anticon-menu-fold {
  padding-left: 30px; }
.sidebar-menu .navbar-logo-collapse img {
  width: 100px !important;
  height: auto; }


/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .header {
/*    background: #606d92 !important;
    background: -webkit-linear-gradient(left, #8b73d3, #66b8c9) !important;
    background: -o-linear-gradient(right, #8b73d3, #66b8c9) !important;
    background: -moz-linear-gradient(right, #8b73d3, #66b8c9) !important;
    background: linear-gradient(to right, #8b73d3, #66b8c9) !important;*/
    color: white !important; }

  .submenu-ui .ant-menu-submenu-popup ul {
    min-height: auto !important;
    height: auto !important; }

  .purple-menu li:last-child {
    width: 100%;
    position: relative;
    bottom: 0; } }
/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .purple-menu li:last-child {
    width: 100%;
    position: relative;
    bottom: 0; } }
/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .sidebar {
    top: 93px;
    bottom: 0; }

  .purple-menu li:last-child {
    width: 100%;
    position: relative;
    bottom: 0; } }
/* ----------- iPhone X ----------- */
/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .sidebar-wrapper .ant-menu-vertical {
    height: 323px !important;
    overflow: auto;
    min-height: auto !important; } }
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .header {
/*    background: #606d92 !important;
    background: -webkit-linear-gradient(left, #8b73d3, #66b8c9) !important;
    background: -o-linear-gradient(right, #8b73d3, #66b8c9) !important;
    background: -moz-linear-gradient(right, #8b73d3, #66b8c9) !important;
    background: linear-gradient(to right, #8b73d3, #66b8c9) !important;*/
    color: white !important; }

  .sidebar {
    top: 93px; }

  .purple-menu,
  .menu-wrapper {
    position: relative !important; } }
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .header {
/*    background: #606d92 !important;
    background: -webkit-linear-gradient(left, #8b73d3, #66b8c9) !important;
    background: -o-linear-gradient(right, #8b73d3, #66b8c9) !important;
    background: -moz-linear-gradient(right, #8b73d3, #66b8c9) !important;
    background: linear-gradient(to right, #8b73d3, #66b8c9) !important;*/
    color: white !important; }

  .sidebar {
    top: 141px !important; }

  .purple-menu,
  .menu-wrapper {
    position: relative !important; }

  .submenu-ui.ant-menu-submenu-placement-rightTop {
    top: 136px !important; } }
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }

  a,
  a:visited {
    text-decoration: underline; }

  a[href]:after {
    content: " (" attr(href) ")"; }

  abbr[title]:after {
    content: " (" attr(title) ")"; }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }

  thead {
    display: table-header-group; }

  tr,
  img {
    page-break-inside: avoid; }

  img {
    max-width: 100% !important; }

  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }

  h2,
  h3 {
    page-break-after: avoid; } }

/*# sourceMappingURL=style.css.map */

.gridV2 .grid-content .ant-table,
.gridV2 .grid-content .ant-table .ant-table-thead,
.gridV2 .grid-content .ant-table .ant-table-tbody > tr > td > a,
.gridV2 .grid-content .ant-table .ant-table-tbody .table-actions a,
.gridV2 .grid-content .ant-table .ant-table-tbody > tr > td > span,
.gridV2 .grid-content .ant-table > a,
.gridV2 .custom-filters,
.custom-filters-container .custom-filters,
.gridV2 .custom-filters-table .ant-table .ant-table-tbody > tr > td > span,
.gridV2 .bulk-actions{
  font-size: 16px;
  color: #000!important;
}
.gridV2 .grid-content .ant-table .ant-table-thead > tr > th{
  font-size: 16px;
  color: #0000008a;
  font-weight: 600;
}
.custom-filters-container .custom-filters{
  height: 36px;
}
.gridV2 .grid-datepciker .ant-picker{
  width: 80%;
}
.gridV2 .grid-icon{
  cursor: pointer;
  color: #454545;
}
.gridV2 .custom-filter-selected{
  border: 2px solid #38b88c;
}
.gridV2 .moduleSubMenu .grid-button-new .button-title{
  vertical-align: text-top;
}
.bulk-actions-options {
  border: 1px solid #e5dede;
  box-shadow: 1px 1px #ccc;
}
.grid-search-field input,
.mobile-search input{
	height: 28px;
	margin-bottom: 0;
}
.ant-table-body {
  min-height: 500px;
}
.demo-infinite-container {
  height: 300px;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: white;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.grid-custom-filter-row .only-button{
  display: none;
}
.grid-popup-form{
  top: 50px;
}

.grid-popup-form .ant-drawer-body{
  background-color: #f0eae7;
}

.grid-button-new{
  background-color: #44c699;
  height: 35px;
  min-width: 125px;
  border-radius: 3px;
  text-align: center;
  border: none;
  color: white;
  margin-right: 10px;
  text-transform: uppercase;
}
.grid-button-new:hover,
.grid-button-new:active,
.grid-button-new:focus{
  background-color: #38b88c;
  color:  white;
}
.grid-total-records{
  position: relative;
  bottom: 40px;
  float: left;
  padding-left: 40px;
  font-size: 16px;
}

.grid-popup-form .ant-drawer-close{
  position: absolute;
  right: 0;
  z-index: 0;
}
.grid-popup-form .dashboard{
  display: none;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.grid-popup-form .panel.total{
  min-height: 200px;
}

.custom-filters-container{
  margin-right: 10px;
}


.mobileFiltersGridBtn{
    margin-left: 10px; 
    width: 90%; 
    margin-right: 10px;
}

.mobileFiltesGridFonts span{
    color: rgba(0,0,0)!important;
    font-size: 16px;
}

.gridHeader-tooltip{
  max-width: 280px;
}

.grid-save-view-drawer .ant-drawer-content,
.grid-columns-drawer .ant-drawer-content {
  background-color: white!important;
}
@media (max-width: 310px) {
  .grid-popup-form .button-outline.red{
    min-height: 80px;
  }
}
@media only screen and (min-width: 767px){
  .demo-infinite-container{
    width: 350px;
  }
  .grid-save-view-drawer .content{
    display: flex;
    align-items: center;
  }
  .grid-save-view-drawer .isPublic{
    padding-top: 17px;
  }
  .grid-custom-filter-row:hover .only-button{
    display: block;
  }
}
@media only screen and (max-width: 767px){
 .gridV2 .grid-button-new{
      min-width: 56px;
      width: 56px;
      height: 56px;
      position: fixed;
      bottom: 100px;
      right: 20px;
      z-index: 99;
      border-radius: 50%;
      font-size: 28px;
      box-shadow: 0 2px 2.5px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 24%);
  }
  .gridV2 .grid-search-field{
    display: none;
  }
  .gridV2 .grid-datepciker{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .gridV2 .mobile-search{
    width: 335px;
    position: absolute;
    top: 40px;
    z-index: 1;
    border: 1px solid #c3c3c3;
  }

  .mobile-search input{
    height: 45px;
  }

  .custom-filters-container{
    display: flex;
    flex-direction: column;
  }

  .gridV2 .ant-table-measure-row{
    visibility: collapse;
  }

  .grid-columns-drawer .ant-drawer-body,
  .grid-save-view-drawer .ant-drawer-body{
    padding: 8px;
  }

  .grid-save-view-drawer .isPublic{
    padding-top: 37px;
  }

  .gridHeader-tooltip{
    max-width: 200px;
  }

  .ant-picker-panels{
    flex-direction: column;
  }

}
.survey-container {
  width: 100%;
  background-color: #f2f6ff;
  min-height: 100vh;
  height: auto;
  position: relative;
  overflow-y: hidden;
}

.survey-wrapper {
  max-width: 1300px;
  min-height: 100vh;
  margin: 0px auto;
  padding: 27px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.survey-list-wrapper {
  background-color: #fff;
  color: #000;
  border-radius: 20px;
  padding: 20px 90px 30px;
  position: relative;
  z-index: 5;
}

.bg-icon-wrapper-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.bg-icon-wrapper-right {
  position: absolute;
  bottom: 50%;
  right: 0;
}

.survey-list-text-content h1 {
  text-align: center;
  margin-bottom: 40px;
}

.survey-list-text-content p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
}

.survey-list-wrapper .ant-form-item {
  margin-bottom: 32px;
}

.survey-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.survey-list-lang {
  font-size: 22px;
}

.survey-logo {
  width: 180px;
  height: 70px;
}

.survey-list-wrapper .form-label {
  font-size: 18px;
  margin-bottom: 10px;
}

.survey-list-wrapper .form-label span {
  color: #ff0000;
}

.survey-list-wrapper .ant-radio {
  display: none;
}

.survey-list-wrapper .scale-option {
  display: inline-block;
  width: 48px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6ecfa;
  font-size: 18px;
  border-radius: 3px;
  cursor: pointer;
}

.survey-list-wrapper span.ant-radio + * {
  padding: 0px;
}

.survey-list-wrapper .ant-radio-wrapper-checked .scale-option {
  background-color: #a5ebb9;
}

.survey-list-textarea textarea {
  background: #ffffff;
  box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  resize: none;
  padding: 10px;
  border: none;
  height: 180px !important;
}

.survey-list-select .ant-select {
  width: 100% !important;
  height: 64px !important;
}

.survey-list-select .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.survey-list-select .ant-select-selector {
  width: 100% !important;
  height: 64px !important;
  background-color: #e6ecfa !important;
  border-radius: 3px !important;
  border: none !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.survey-list-multiply-select .ant-select-selection-item {
  background-color: white;
  border-radius: 8px;
  height: auto;
  margin-left: 14px;
}

.survey-list-multiply-select .ant-select-selection-item-content {
  line-height: 36px !important;
}

.survey-list-multiply-select .ant-select-clear {
  background-color: transparent;
  right: 45px;
  transform: translateY(-50%);
}

.survey-list-select-clear-icon {
  font-size: 22px;
}

.survey-list-checkbox .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.survey-list-checkbox .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 30px;
}

.survey-list-checkbox .ant-checkbox-wrapper span:last-child {
  font-size: 14px;
}

.survey-list-checkbox .ant-checkbox-inner {
  width: 32px;
  height: 32px;
  position: relative;
  margin: 20px auto;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}

.survey-list-checkbox .ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #a5ebb9;
  border-color: #a5ebb9;
  background-clip: content-box, padding-box;
}

.survey-list-checkbox .checkbox-text {
  margin-top: 5px;
  margin-bottom: 0px;
}

.survey-list-checkbox .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.survey-list-checkbox .ant-checkbox-wrapper span:last-child {
  padding: 5px !important;
}

.survey-list-checkbox .ant-checkbox-inner::after {
  border: none;
}

.survey-list-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  display: none;
}

.survey-list-checkbox .ant-checkbox:hover::after,
.survey-list-checkbox .ant-checkbox-wrapper:hover .ant-checkbox::after {
  border: none;
}

.survey-list-buttons {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 30px;
  gap: 30px;
}

.thanks-text {
  text-align: center;
}

.survey-btn-clear {
  width: auto !important;
  height: auto !important;
  padding: 8px 20px;
  font-size: 18px;
  background: #e6ecfa;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: none;
  transition: all 0.3s ease;
}

.survey-btn-clear span {
  padding: 0 !important;
}

.survey-btn-clear:hover {
  color: #e6ecfa;
  background-color: #000;
}

.survey-btn-submit {
  width: auto !important;
  height: auto !important;
  padding: 8px 20px;
  font-size: 18px;
  background: #a5ebb9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: none;
  transition: all 0.3s ease;
}

.survey-list-buttons .survey-btn-submit.ant-btn:focus {
  background: #a5ebb9;
  color: #000;
}

.survey-list-buttons .survey-btn-clear.ant-btn:focus {
  background: #e6ecfa;
  color: #000;
}

.survey-btn-submit span {
  padding: 0 !important;
}

.survey-btn-submit:hover {
  color: #a5ebb9;
  background-color: #000;
}
.survey-footer {
  margin-top: 90px;
  z-index: 20;
  position: relative;
}

.success-text {
  text-align: center;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .survey-wrapper {
    padding: 10px 20px;
  }
  .survey-list-wrapper {
    padding: 10px;
    position: relative;
    z-index: 5;
  }
  .survey-list-text-content p {
    font-size: 16px;
    line-height: 22px;
  }
  .survey-btn-clear {
    padding: 5px 10px;
  }

  .survey-btn-submit {
    padding: 5px 10px;
  }

  .survey-list-checkbox .ant-checkbox-inner {
    width: 28px;
    height: 28px;
  }

  .survey-list-select .ant-select-selector {
    height: 48px !important;
  }
}

.signatureCanvas {
  border: 1px solid black;
  width: 300px;
  height: 200px;
  cursor: crosshair;
}

.page-container {
  position: relative;
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: start;
  grid-gap: 44px;
  gap: 44px;
}

.page {
  width: 21cm;
  height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border-radius: 5px;
  background: white;
  /* top: 50px; */
  /* left: 420px; */
  overflow-y: scroll;
}

/* .info-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background: blueviolet;
} */

/* .side-container {
  width: 50%;
} */

.document-container {
  overflow: hidden;
}

.actions-container {
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  z-index: 1;
  /* width: 50%; */
}

.actions-container p {
  font-weight: 700;
  font-size: 18px;
}

.draggable-container {
  position: absolute;
  bottom: 0;
  left: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin: 0px 16px; */
}

@media (max-width: 1652px) {
  .page-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
  }

  .side-container {
    display: flex;
    justify-content: center;
    align-items: start;
    grid-gap: 16px;
    gap: 16px;
  }
}
@media (max-width: 720px) {
  .page-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
  }

  .side-container {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    /* padding: 0px; */
    width: 100%;
  }

  .page {
    /* width: 21cm; */
    height: 29.7cm;
    padding: 2cm;
    margin: 1cm auto;
    border-radius: 5px;
    background: white;
    /* top: 50px; */
    /* left: 420px; */
    overflow-y: scroll;
  }
}

* {
  scrollbar-width: thin; /* "thin", "auto", or "none" */
}

/* Set the color of the scrollbar thumb and track */
* {
  scrollbar-color: #888 #eee; /* thumb-color track-color */
}

/* For Webkit-based browsers like Chrome and Safari */
/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 10px; /* You can adjust this value */
}

/* Set the color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; /* You can use any color */
}

/* Optionally, set the color of the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #eee; /* You can use any color */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Toastify__toast-container {    
  margin: 0 auto;
  text-align: center;
  height: 50px;
  font-size: 18px;
  line-height: 2;
  color: white;
  border-radius: 10px 10px 0px 0px;
}

.Toastify__toast {  
  position: relative;
  min-height: 84px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  color: white;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast Toastify__toast--error{
   border-radius: 10px 10px 0px 0px;
}
.grow-font-size {
   border-radius: 10px 10px 0px 0px;
}

.black-background {
 border-radius: 10px 10px 0px 0px;
}

.Toastify__toast-container--bottom-center {
 border-radius: 10px 10px 0px 0px;
}

.Toastify__toast--success {
border-radius: 10px 10px 0px 0px !important;
background: #44c699 !important;
}  
.Toastify__toast--warning {
border-radius: 10px 10px 0px 0px !important;
}
.Toastify__toast--error {
border-radius: 10px 10px 0px 0px !important;
 background: #e50000 !important;
}
.toastBody {
border-radius: 10px 10px 0px 0px !important;
}
.toastProgress {
border-radius: 10px 10px 0px 0px !important;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 52%;
  /* right: 10%; */
  width: 80%;
  transform: translateX(-50%);

}

.printData {
  background-color: white;
  margin: auto;
  height: 100%;
  max-width: 70%;
  margin-bottom: 50px;
  border-radius: 2px;
  box-shadow: 0 0 7px 0 #d4d4d4;
  overflow: auto;
  padding: 40px;
}

.form-select-basic {
  display: block;
  width: 240px;
  height: 60px;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  color: #212529;
  background-color: #fff;

}

@media (max-width: 767px) {
  body{
    overflow: scroll;
  }

  .ant-drawer-content-wrapper{
    width: 100%!important;
  }

  .mobileMenu .ant-menu-item{
    font-size: 22px!important;
  }
  .mobileMenu .ant-menu-submenu-title{
    font-size: 22px!important;
  }
}

.account-profile .account-profile-information {
  border: 1px solid lightgray;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 7px 0 #d4d4d4;
  padding-bottom: 15px;
  z-index: 10;
}

.small-select.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 25px;
}

.chrome-picker{
  width: 150px !important;
  transform: translate(-86px, -4px);
}

.block-picker{
  width: 150px !important;
  transform: translate(-86px, -4px);
}

.swatches-picker {
  width: 182px !important;
  height: 100px;
  transform: translate(-102px, -93px);
  z-index: 5;
}

.color_picker {
  position: absolute;
  transform: translate(-205px, -2px);
  z-index: 15;
}


.wrapper{
	top:  45px;
}

.sidebar{
	width: 170px;
	position: absolute;
	top:  50px;
}

#main-content.merge-left{
	width: calc(100% - 80px);
}

.hidе-group-label .ant-menu-item-group-title{
	display: none;
}

.navbar-logo-collapse img{
	width: 100px;
}

.purple-menu .ant-menu-submenu .ant-menu-submenu-title{
	padding-left: 10px;
}

.purple-menu .ant-menu-item{
	padding-left: 10px!important;
}
.sidebar.sidebar-collapsed .purple-menu .ant-menu-item{
	padding-left: 15px!important;
}

.sidebar.sidebar-collapsed .purple-menu .ant-menu-item.menu-expand{
	padding-left: 15px!important;
}

.sidebar.sidebar-collapsed{
	width: 80px!important;
}

.sidebar ul li a{
	display: inherit!important;
	font-size: 15px!important;
	padding:  0!important;
}

.sidebar .purple-menu .ant-menu-submenu-selected,
.sidebar .purple-menu .ant-menu-item-selected a,
.submenu-ui .ant-menu-title-content a,
.sidebar .purple-menu .ant-menu-item a:hover,
.sidebar .purple-menu .ant-menu-title-content a{
	color:  #444;
	font-size: 15px;
}
/*
    Created on : Mar 10, 2020, 10:25:14 PM
    Author     : veronika
*/

/*CUSTOM DATA*/
.single-block{
   padding: 10px 0px;
   border-bottom: 1px solid #e8e8eb;
   cursor: pointer;
}
.block-header span{
   font-size: 18px;
   font-weight: 600;
}

/*
    Created on : Sep 16, 2020, 10:59:06 AM
    Author     : vk
*/
/*HEADER*/
.containment {
    position: absolute;
    z-index: 999;
    height: 20px;
    left:  200px;
}
.companyLogo img{
    max-width: 100%;
    max-height: 50px;
}
.containment-old {
    display: none;
}
.header.fixed-top {
    width: 100%!important;
    position: fixed;
    top: 0;
    right: 0;
    align-items: center;
}
/*.header.fixed-top.collapsed{
    left:  80px;
    width: calc(100% - 80px)!important;
}*/

.add-fast-button {
    width: 22px;
    height: 22px;
    background: #ff3465;
    float: left;
    border-radius: 100%;
    text-align: center;
    line-height: 23px;
    font-size: 18px;
    color: white;
    margin-top: 0px;
}

.add-fast-button:hover .ion-android-add,
.add-fast-button .ion-chevron-down {
    display: none;
}
.add-fast-button:hover .ion-chevron-down {
    display: inline;
    color: white;
    font-size: 13px;
        cursor: pointer;
}

.header .ant-menu-horizontal .ant-menu-item i, .header .ant-menu-horizontal .submenu-title-wrapper i, .ant-menu-horizontal > .ant-menu-submenu{
    font-size: 24px;
    color: #fff;
}
.header .mobileLink{
    display: none;
}

/* SEARCH */
.globalSearch-box{
    margin: 5px 0px;
}
.globalSearch .ant-select-selection{
    height: 48px;
    color: black;
}
.globalSearch .ant-select-selection__rendered, .globalSearch .ant-select-search__field{
    line-height: 48px;
}
.globalSearch .ant-select-selection__placeholder{
    color: #7f7f7f;
}
.globalSearch .ant-select-search__field__mirror{
    padding-left: 20px;
}
.globalSearch-link{
    padding: 10px 130px 10px 10px;
}
.globalSearch-select{
    width: 100%;
}
.globalSearch-select .ant-select-selection.ant-select-selection--multiple{
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 16px;
    color: #000;
}

.globalSearch-select .ant-select-selection.ant-select-selection--single{
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 32px;
    font-size: 16px;
    color: #000;
}

.admin-menu li{
    padding-left: 25px;
}

.starter-plan:hover, .starter-plan.ant-menu-item-selected{
    border-bottom: 2px solid transparent!important;
}

.header .icon{
    float: right;
    border-right: 1px solid #fff;
    padding: 0 15px 0 0;
    line-height: 60px;
    height: 50px;
    color: #e3e3e3;
    font-size: 28px;
    margin: 0 15px 0 0;
}


/* NOTIFICATIONS */
.notification_menu, .help_menu{
    display: block;
    right: 43px;
    left: unset;
    padding: 0px;
    max-width: 450px;
    position: fixed;
    top:50px;
}
.notification-header{
    background-color: #f8f8f8;
    line-height: 65px;
}
.help-header{
    background-color: #f8f8f8;
    line-height: 35px;
    padding: 5px 10px;
}
.help_menu a{
    line-height: 40px!important;
}
.shortcuts{
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.user-header{
    line-height: 35px;
    padding: 5px 10px;
}
.username {
    font-weight: bold;
    text-transform: capitalize;
    padding-left: 10px;
    font-size: 16px;
}
.clear-all {
    margin-right: 15px;
}
.notif {
    padding-left: 20px;
    padding-right: 10px;
    line-height: 25px;
    padding-top: 10px;
    display: table;
    border-bottom: 1px solid #eee;
}
.notification_array .notif:hover {
    background: #f7f7f7;
}
.notif i {
   padding-right: 7px;
}
.notif i.unread {
    color: #ff6e2c;
}
.notif-i{
    display: flex;

}
.notif .notif-edit {
    cursor: pointer;
    display: flex;
    margin: 2px 0;
}
.notif-desc{
    width: 330px;
  }
.notif_date {
    font-size: 12px;
    color: #b9b9b9;
}

.notification-counter {   
    position: absolute;
    top: 6px;
    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;
}

.header .ant-menu-horizontal{
    background: transparent;
}
@media only screen and ( min-width: 768px){
    .header .ant-menu-horizontal{
        border-bottom: none;
        justify-content: flex-end;
        padding-right: 20px;
    }
}

@media only screen and ( max-width: 767px) {
  .containment{
    position: relative;
    height: 93px;
    left: 0;
  }
  .wrapper{
    top:  0;
  }
  .header.fixed-top {
    width: 100%;
    position: fixed;
  }
  .companyLogo img, .add-fast-button {
    display: none;
  }
  header .notification_menu, header .help_menu{
    top: 125px;
    right: 0;
  }
  .globalSearch-mobile{
    margin-left: 40px;
  }

  #printer-link-new{
    display: none;
  }
  .header .ant-menu-horizontal .ant-menu-item i, .header .ant-menu-horizontal .submenu-title-wrapper i, .ant-menu-horizontal > .ant-menu-submenu{
        font-size: 22px;
   }
}

